/**
 * Created by BETALOS on 27/12/2016.
 */
(function () {

    'use strict';

    const VISUALIZE_FILE_DIALOG = require('stand-alone/file-manager/dialogs/visualize-file-dialog');

    class ImportExamDialogCtrl {
        constructor($mdDialog, interfacingService, $timeout) {
            this.dialog = $mdDialog;
            this.$timeout = $timeout;
            this.interfacingService = interfacingService;

            this.selectExam = (exam, ev) => this.selectExamFunc(exam, ev);
        }

        static get $inject() {
            return ["$mdDialog", "interfacingService", "$timeout"];
        }

        $onInit() {
            this.preSave = this.preSave ? this.preSave : null;
            this.patient = this.patient ? this.patient : null;
            this.context = this.context ? this.context : null;
            this.noClick = this.noClick ? this.noClick : false;
            this.itemClick = this.itemClick ? this.itemClick : null;
            this.singleActions = this.singleActions ? this.singleActions : null;

            this.actions = {
                single: this.singleActions ? this.singleActions : [
                    {
                        action: 'get',
                        icon: 'mdi-file',
                        behavior: 'disable',
                        resource: 'interfacing',
                        label: 'visualize_joined_file',
                        method: (item, $event) => this.visualize(item, $event)
                    }
                ],
                multiple: []
            };
        }

        cancel() {
            this.dialog.cancel();
        }

        visualize(item, $event) {
            $event.stopPropagation();

            if (_.isNull(item.file)) return;

            const file = _.chain(item.file).assign(_.pick(item, 'id')).castArray().value();

            const dialog = _.assign({}, VISUALIZE_FILE_DIALOG, {
                targetEvent: $event,
                locals: {files: file, fileIndex: 0, filePath: "/api/received-data/", showPreview: false}
            });

            this.dialog.show(dialog, _.noop);
        }

        selectExamFunc(exam, ev) {
            if (this.noClick) return;
            else if (this.resolve) this.dialog.hide(exam);
            else if (!_.isNil(this.itemClick)) this.itemClick(exam, ev, this.promise);
            else if (!_.isNil(this.preSave)) this.promise = this.preSave().then(() => this.startSave(exam));
            else this.promise = this.startSave(exam);
        }

        startSave(exam) {
            this.$timeout(() => {
                this.promise = this.interfacingService.handleInterfacingInstance(this.patient, exam, this.context)
                    .then(this.dialog.hide);
            }, 200);
        }

        clearQuery() {
            this.query = _.pick(this.query, ['default_config', 'exam_files']);
        }

    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        controller: ImportExamDialogCtrl,
        template: require("../views/import-exam-dialog.tpl.html"),
    };

})();