/**
 * Created by amine on 13/04/2018.edited 03/10/2022.
 */
(function () {

    'use strict';

    class VaccineFormDialogController {
        constructor($mdDialog, vaccinationService) {
            this.dialog = $mdDialog;
            this.vaccinationService = vaccinationService;

            this.vaccine = {};
        }

        static get $inject() {
            return ["$mdDialog", "vaccinationService"];
        }

        $onInit() {

        }

        submit() {
            this.vaccinationService.saveVaccine(this.vaccine)
                .then(this.dialog.hide)
        }

        cancel() {
            this.dialog.cancel();
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: VaccineFormDialogController,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("./vaccine-form.tpl.html"),
    };

})();