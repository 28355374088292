/**
 * Created by amine on 29/07/2019.  V2.0
 */

(function () {
    'use strict';

    const VISUALIZE_FILE = require('stand-alone/file-manager/dialogs/visualize-file-dialog');


    class PatientSummaryCtrl {
        constructor($q, mnWebSocket, $translate, patientService, visitService, uploadService, moment, $mdDialog, system, $scope, $element) {
            this.$q = $q;
            this.$mdDialog = $mdDialog;
            this.mnWebSocket = mnWebSocket;
            this.patientService = patientService;
            this.visitService = visitService;
            this.uploadService = uploadService;
            this.moment = moment;
            this.$scope = $scope;
            this.$element = $element;
            this.dateFormat = system['date_format'].js;

            this.affiliates = {
                PATIENT: $translate['instant']("insurance_himself"),
                PARTNER: $translate['instant']("insurance_partner"),
                CHILD: $translate['instant']("insurance_child"),
                PARENT: $translate['instant']("insurance_parent")
            };
            this.genders = {
                MALE: {
                    icon: 'mdi-human-male',
                    color: "#42A5F5" //blue 300
                },
                FEMALE: {
                    icon: 'mdi-human-female',
                    color: "#F06292" //pink 400
                }
            };

            this.promise = this.promise || null;
            this.patient = this.patient || null;
            this.visits = [];
            this.filter = null;
            this.query = null;
            this.noPatientImage = true;

        }

        static get $inject() {
            return [
                "$q", "mnWebSocket", "$translate", "patientService", "visitService", "uploadService", "moment", "$mdDialog",
                "system", "$scope", "$element"
            ];
        }

        $onInit() {
            this.filter.subscribe(
                () => this.loadData()
            );
        }

        loadData() {
            if (_.isNil(this.patient)) return false;
            else if (_.isNumber(this.patient)) {
                this.patient = {id: this.patient};
            }

            this.query = this.filter.getValue();

            this.promise = this.$q.all([
                this.patientService.getFormPatient(this.patient.id),
                this.visitService.getPatientVisits(this.patient.id, {
                    start: _.get(this.query, "start_date"),
                    end: _.get(this.query, "end_date")
                })
            ]).then(data => {
                this.patient = data[0];
                this.visits = data[1];
                this.handlePatientImage(this.patient.id);
            }, _.noop);
        }

        handlePatientImage(id) {
            const img = $('img.patient-avatar', this.$element).get(0);
            const imgSrc = `/api/patient/${id}/data/?t=${this.moment().valueOf()}`;

            this.uploadService.downloadFileAsBlob(imgSrc)
                .then(url => img.src = url);

            img.onload = () => {
                this.$scope.$applyAsync(() => this.noPatientImage = false);
            };

            img.onerror = () => {
                this.$scope.$applyAsync(() => this.noPatientImage = true);
            };
        }

        viewImage(ev) {
            if (this.patient && this.patient.id) {
                const file = {name: '', mime: 'image/png', url: `/api/patient/${this.patient.id}/data/`};

                const dialog = _.assign({}, VISUALIZE_FILE, {
                    targetEvent: ev,
                    locals: {files: _.castArray(file), fileIndex: 0, allowEdit: false}
                });

                this.$mdDialog.show(dialog, _.noop);
            }
        }
    }

    module.exports = {
        bindings: {
            change: "&ngChange",
            patient: "=ngModel",
            filter: "=",
            // export: "=?",
            promise: "=?"
        },
        template: require("stand-alone/patient-summary/views/main.tpl.html"),
        controllerAs: "vm",
        controller: PatientSummaryCtrl,
    };

})();