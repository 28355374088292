(function () {

    'use strict';

    const ImageViewer = require('iv-viewer').default;

    const KNOWN_PDFS = [
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/msword',
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.ms-powerpoint'
    ];

    const KNOWN_EXCELS = [
        'application/vnd.ms-excel',
        'application/wps-office.xls',
        'application/wps-office.xlsx',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ];

    class FileVisualizerCtrl {
        constructor($element, $auth, $compile, $scope) {
            this.$auth = $auth;
            this.$scope = $scope;
            this.$element = $element;
            this.$compile = $compile;

            this.file = null;
            this.urlPath = null;
            this.editImage = null;
            this.childScope = null;
            this.imageViewer = null;
        }

        static get $inject() {
            return ["$element", "$auth", "$compile", "$scope"];
        }

        $onInit() {
            this.$scope.$watch('vm.file', (newVal, oldVal) => this.handleElement(newVal, oldVal));
            this.$scope.$watch('vm.editImage', () => this.handleElement());
        }

        handleElement(newVal, oldVal) {
            if ((newVal && oldVal && newVal.id === oldVal.id) || _.isEmpty(this.file)) return;

            this.url = this.constructUrl();
            this.isImage = _.includes(this.file.mime, 'image');

            if (!_.isNull(this.childScope)) this.childScope.$destroy();
            if (this.imageViewer && !_.includes(this.file.mime, 'image')) this.imageViewer = this.imageViewer.destroy();


            // to wait the previous component to be correctly destroyed
            setTimeout(() => {
                if (_.includes(this.file.mime, 'video')) this.createVideoTag();
                if (_.includes(this.file.mime, 'audio')) this.createAudioTag();
                if (_.includes(this.file.mime, 'image')) this.createImageTag();
                if (_.includes(KNOWN_PDFS, this.file.mime)) this.createComponentTag('<mn-pdf-viewer />');
                if (_.includes(this.file.name, 'stl')) this.createComponentTag('<mn-stl-viewer />');
                if (_.includes(KNOWN_EXCELS, this.file.mime)) this.createComponentTag('<mn-excel-viewer />');
            }, 100);
        }

        constructUrl() {
            if (_.has(this.file, 'url')) return `${this.file.url}${_.includes(this.file.url, "?") ? "&auth=" : "?auth="}${this.$auth.getToken()}`;
            else return `${this.urlPath}${this.file.id}/data/?auth=${this.$auth.getToken()}`;
        }

        createVideoTag() {
            const $video = $('<video />', {controls: true, autoplay: true})
                .append(`<source src="${this.url}" type="video/webm" />`);

            this.$element.html($video);
        }

        createAudioTag() {
            const audio = $('<audio />', {controls: true, autoplay: true})
                .append(`<source src="${this.url}" type="audio/mp3" />`);

            this.$element.html(audio);
        }

        createImageTag() {
            this.$element
                .removeClass('layout')
                .removeClass('layout-fill')
                .addClass('layout-column');

            let src = this.file.isBase64 ? this.file.content : this.url;

            if (this.editImage) {
                const image = $('<mn-image-edition />', {url: 'vm.url', class: 'flex', 'image-title': this.file.name});
                this.handleTagCompile(image);
            } else if (this.imageViewer) this.imageViewer.load(src);
            else {
                let $image = $('<img />', {src});

                this.$element.html($image);
                this.$element.addClass('layout-align-center-center');

                this.imageViewer = new ImageViewer($image.get(0));
            }
        }

        createComponentTag(tag) {
            const elem = $(tag, {src: this.url});
            this.handleTagCompile(elem);
        }

        handleTagCompile(elem) {
            this.childScope = this.$scope.$new();
            let compiledDirective = this.$compile(elem);
            let directiveElement = compiledDirective(this.childScope);

            this.$element.html(directiveElement);
        }

    }

    module.exports = {
        template: "",
        bindings: {
            file: "=",
            isImage: "=",
            urlPath: "<",
            editImage: "=",
        },
        controllerAs: "vm",
        controller: FileVisualizerCtrl,
    };

})();
