/**
 * Created by amine on 12/04/2018.// edited 16/07/2020
 */
(function () {
    'use strict';

    const VACCINATION_LINE_FORM_DIALOG = require("../../dialogs/vaccination-line-form-dialog/vaccination-line-form-dialog");
    const VACCINATION_CONFIG_CONTAINER_DIALOG = require("../../dialogs/vaccination-config-container-dialog/vaccination-config-container-dialog");

    class VaccinationCalendarCtrl {
        constructor($timeout, $scope, system, $translate, $q, vaccinationService, authService, patientService, $mdDialog, frontDeskService, configService, moment) {
            this.dateFormat = system.date_format.js;

            this.$q = $q;
            this.scope = $timeout;
            this.translate = $translate;
            this.dialog = $mdDialog;
            this.patientService = patientService;
            this.vaccinationService = vaccinationService;
            this.configService = configService;
            this.authService = authService;
            this.frontDeskService = frontDeskService;
            this.moment = moment;

            this.promise = null;
            this.patient = this.patient || null;
            this.postSaveSubject = this.postSaveSubject || null;
            this.readOnly = this.readOnly || false;
            this.postSave = this.postSave || _.noop;
            this.saving = false;
            this.savingConsultation = false;
            this.config = {};
            this.vaccination = null;
            this.hierarchy = {};
            this.calendar = null;
            this.selectedComment = null;
            this.showHided = false;

        }

        static get $inject() {
            return ["$timeout", "$scope", "system", "$translate", "$q", "vaccinationService", "authService", "patientService", "$mdDialog", "frontDeskService", "configService", "moment"];
        }

        $onInit() {
            const promises = [
                this.vaccinationService.getCalendar(this.patient),
                this.configService.get("vaccination_block_config")
            ]

            this.vaccinationDateChanged = _.mnDelay((item = null) => this._vaccinationDateChanged(item), 750);
            this.delayedSave = () => this.promise = this.submit(); //_.mnDelay(, 750);

            if (!_.isNil(this.postSaveSubject) && !this.readOnly) this.postSaveSubject
                .subscribe(next => this.consultationSaved(next));

            this.$q.all(promises)
                .then(data => this.loadPatientCalendar(data));
        }

        loadPatientCalendar(data) {
            if (_.isArray(data)) this.config = data[1] || {hide_appointment_column: false, fix_estimated_dates: false};

            this.vaccination = _.isArray(data) ? data[0] : data;
            this.hierarchy = {};

            this.generateCalendar();
        }

        iterLines(line) {
            const ids = line['vaccination_ids'];
            if (_.isNil(this.hierarchy[ids])) this.hierarchy[ids] = [];

            this.hierarchy[ids].push(line);
            const expectedDate = this.dateSegment(line.age['min_value'], line.age['max_value'], line.age['time_unit'], true);

            return {
                order: this.translate.instant(
                    line['is_booster'] ? "vaccination_booster_order" : "vaccination_injection_order",
                    {order: line['injection_order']}
                ),
                expected_date: expectedDate,
                default_expected_date: expectedDate,
                line: line
            };
        }

        iterGroups(group) {
            const line = _.first(group).line;
            let title = "";

            if (line.age.is_interval) {
                title = this.translate.instant("vaccination_vaccines_interval_title", {
                    min: this.ageSegment(line.age['min_value']),
                    max: this.ageSegment(line.age['max_value']),
                    unit: this.translate.instant("vaccination_tu_" + line.age['time_unit'])
                })
            } else if (line.age.is_infinite) {
                title = this.translate.instant("vaccination_vaccines_infinite_title", {
                    min: this.ageSegment(line.age['min_value']),
                    unit: this.translate.instant("vaccination_tu_" + line.age['time_unit'])
                })
            } else {
                title = this.ageSegment(line.age['min_value'], line.age['time_unit'])
            }

            return {
                title: title,
                age_by_days: line.age['age_by_days'],
                expected_date: this.dateSegment(line.age['min_value'], line.age['max_value'], line.age['time_unit']),
                details: group,
                show: function () {
                    return this.details.length > 0 && !_.every(this.details, ['line.is_hidden', true])
                }
            };
        }

        ageSegment(value, segmentType) {
            const _tu = _.isNil(segmentType) ? "" : this.translate.instant(`vaccination_tu_${segmentType}`)
            return `${value} ${_tu}`;
        }

        dateSegment(minValue, maxValue, type, unique) {
            const birthDate = this.moment(this.vaccination.patient['birth_date'], this.dateFormat);
            minValue = parseInt(minValue);

            if (_.isNil(maxValue) || unique) {
                return birthDate.add(minValue, type + "s").format(this.dateFormat);
            } else {
                maxValue = parseInt(maxValue);
                let start = this.moment(birthDate).add(minValue, `${type}s`).format(this.dateFormat);
                let end = this.moment(birthDate).add(maxValue, `${type}s`).format(this.dateFormat);

                return `${start} - ${end}`;
            }
        }

        generateCalendar() {
            this.calendar = _.chain(this.vaccination.lines)
                .cloneDeep()
                .values()
                .map(line => this.iterLines(line))
                .sortBy(['line.injection_order'])
                .groupBy('line.age.age_by_days')
                .map(group => this.iterGroups(group))
                .sortBy('age_by_days')
                .value();

            if (this.config.fix_estimated_dates) {
                this.hierarchy = _.chain(this.hierarchy)
                    .reduce(
                        (result, items, ids) => {
                            let hierarchyGroup = [];
                            items.forEach(line => {
                                let groupIndex = _.findIndex(this.calendar, ['age_by_days', line['age']['age_by_days']]);
                                let group = this.calendar[groupIndex];
                                let lineIndex = _.findIndex(group.details, ['line.injection_uid', line.injection_uid]);

                                hierarchyGroup.push({
                                    uid: line['injection_uid'],
                                    age_by_days: group.age_by_days,
                                    index: `${groupIndex}.details.${lineIndex}`
                                })
                            });

                            return _.assign(result, {[ids]: _.sortBy(hierarchyGroup, "age_by_days")});
                        }, {}
                    )
                    .value();

                this.calendar.forEach(group => {
                    _.filter(group.details, line => !_.isNil(line.line.vaccination_date))
                        .forEach(line => this._vaccinationDateChanged(line, true));
                });
            }
        }

        _vaccinationDateChanged(item, skipSaving = false) {
            if (_.isNull(item)) return false;

            if (!!_.get(this.config, 'fix_estimated_dates', false)) {
                let birth_date = this.moment(this.vaccination.patient['birth_date'], this.dateFormat);
                let hierarchyGroup = this.hierarchy[item.line.vaccination_ids];
                let current = _.findIndex(hierarchyGroup, ["uid", item.line.injection_uid]);
                let itemExpectedDate = this.moment(item.default_expected_date, this.dateFormat);
                let vaccinationDate = this.moment(item.line.vaccination_date, this.dateFormat);
                let diff = vaccinationDate.diff(itemExpectedDate, 'days');

                _.slice(hierarchyGroup, current + 1).forEach(sibling => {
                    let elem = _.get(this.calendar, sibling.index);
                    let expectedDate = this.moment(birth_date)
                        .add(parseInt(elem.line.age['min_value']), `${elem.line.age['time_unit']}s`)
                        .add(diff, 'days');

                    _.set(this.calendar, `${sibling.index}.edited_expected_date`, true);
                    _.set(this.calendar, `${sibling.index}.expected_date`, expectedDate.format(this.dateFormat));
                });
            }

            if (!skipSaving) this.delayedSave();
        }

        setAppointment(item) {
            const defaultReason = _.get(this.configService.defaultValues, "entry.reason", null);
            const reason = _.find(this.frontDeskService.reasonSubject.getValue(), ['id', 2]) || defaultReason;

            const defaultPhysician = _.get(this.configService.defaultValues, "physician", null);
            const physician = this.authService.staff || defaultPhysician;

            item.line.provisional_date = item.line.planned_date;

            item.line.appointment = {
                reason,
                physician,
                patient: this.vaccination.patient,
                is_waiting_list: true,
                waiting_list_comment: this.translate.instant('vaccination_wl_comment_w_date', {
                    date: item.line.planned_date,
                    title: item.line.title
                })
            };

            this.delayedSave();
        }

        openAppointmentDialog(item) {
            const line = item.line;
            const deferred = this.$q.defer();
            let event = {};
            let appointmentDate = _.get(line, 'appointment.date', false) ? item.line.appointment.date : item.expected_date;

            if (_.has(line, 'appointment.id')) {
                event = {
                    id: line.appointment.id,
                    assignIn: {
                        date: appointmentDate,
                        //is_waiting_list: false
                    }
                }
            } else {
                const defaultReason = _.get(this.configService.defaultValues, "entry.reason", null);
                const reason = _.find(this.frontDeskService.reasonSubject.getValue(), ['id', _.get(item, "reason.id")]) || defaultReason;

                event = {
                    date: appointmentDate,
                    start_time: "09:00",
                    end_time: `09:${_.get(reason, 'appointment_duration', 10)}`,
                    patient: this.vaccination.patient,
                    waiting_list_comment: this.translate.instant('vaccination_wl_comment', {title: item.line.title}),
                    reason
                }
            }

            deferred.resolve(event);

            return deferred.promise;
        }

        editAppointment(detail) {
            return this.openAppointmentDialog(detail);
        }

        noGroupToShow() {
            if (_.isNil(this.calendar)) return true;

            return !this.calendar.length || (!this.showHided && _.every(this.calendar, ['show', false]))
        }

        linesToDict() {
            if (!this.vaccination) return false;

            this.vaccination.lines = _.chain(this.calendar)
                .cloneDeep()
                .flatMap('details')
                .map('line')
                .keyBy('injection_uid')
                .value()
        }

        submit() {
            if (!this.vaccination) return this.$q.resolve(null);

            this.linesToDict();

            this.saving = true;
            this.promise = this.vaccinationService.saveCalendar(this.vaccination)
                .then(data => {
                    this.vaccination = data;
                    this.hierarchy = {};

                    this.generateCalendar();

                    if (!this.savingConsultation) {
                        this.postSave();
                    } else {
                        this.savingConsultation = false;
                    }
                }, _.noop);

            return this.promise;
        }

        editLine(uid, event) {
            this.dialog.show(_.assign({}, VACCINATION_LINE_FORM_DIALOG, {
                targetEvent: event,
                locals: {
                    vaccination: this.vaccination,
                    line: _.cloneDeep(this.vaccination.lines[uid])
                }
            })).then(data => this.loadPatientCalendar(data), _.noop);
        }

        addLine(uid, event) {
            this.dialog.show(_.assign({}, VACCINATION_LINE_FORM_DIALOG, {
                targetEvent: event,
                locals: {
                    vaccination: this.vaccination,
                    line: {
                        injection_order: 1
                    }
                }
            })).then(data => this.loadPatientCalendar(data), _.noop);
        }

        toggleAppearanceLine(item) {
            item.line.is_hidden = !item.line.is_hidden;

            this.delayedSave();
        }

        toggleHided() {
            this.showHided = !this.showHided;
        }

        openSettings(event) {
            this.dialog.show(_.assign({}, VACCINATION_CONFIG_CONTAINER_DIALOG, {
                targetEvent: event,
            }));
        }

        commentLine(item) {
            if (this.selectedComment === item.line.injection_uid) this.selectedComment = null;
            else this.selectedComment = item.line.injection_uid;
        }

        consultationSaved() {
            if (!this.saving) {
                this.savingConsultation = true;
                this.delayedSave();
            } else this.saving = false;
        }
    }

    module.exports = {
        bindings: {
            patient: '<?patient',
            postSave: '&?postSave',
            postSaveSubject: '<?postSaveSubject',
            readOnly: '<?readonly',
        },
        template: require("./vaccination-calendar-block.tpl.html"),
        controllerAs: "vm",
        controller: VaccinationCalendarCtrl,
    };
})();
