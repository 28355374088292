/**
 * Created by BETALOS on 14/06/2016.
 */
(function () {

    'use strict';

    module.exports = dictionaryService;

    const {Subject} = require("rxjs");
    const DICTIONARY_HISTORY_DIALOG = require('stand-alone/blocks/dialogs/dictionary-history-dialog/dictionary-history-dialog');

    dictionaryService.$inject = ["$q", "configService", "mnWebSocket", "$mdDialog", "storageService"];

    function dictionaryService($q, configService, mnWebSocket, $mdDialog, storageService) {
        let self = this;
        let dictionary = {groups: {}, dictionary_models: {}};

        self.dictModelSubject = new Subject();

        self.dictionaryConfig = null;
        self.currentFieldSubject = null;
        self.getDictionary = getDictionary;
        self.getDictionaryID = getDictionaryID;

        self.getDictionaryGroups = getDictionaryGroups;
        self.editDictionaryConfig = editDictionaryConfig;

        self.getGroup = getGroup;
        self.updateGroup = updateGroup;
        self.editDictionaryGroup = editDictionaryGroup;

        self.getModels = getModels;
        self.updateModels = updateModels;
        self.removeModel = removeModel;

        self.getFieldHistory = getFieldHistory;


        function getGroup(title) {
            const group = _.get(dictionary.groups, title, {});
            return self.dictionaryConfig['is_long_value'] ? prepareDictionary(group, true) : _.castArray(group);
        }

        function getModels(title) {
            return _.isUndefined(dictionary['dictionary_models'][title]) ? [] : dictionary['dictionary_models'][title];
        }

        function getDictionary() {
            const deferred = $q.defer();

            $q.all([
                //mnWebSocket.call('shared.Dictionary.get_by_owner', {}),
                storageService.loadPromise('user-dictionary', [mnWebSocket.call, 'shared.Dictionary.get_by_owner', {}]),
                configService.get("dictionary_config", true)
            ]).then(success, deferred.reject);

            function success(data) {
                dictionary = data[0];
                self.dictionaryConfig = fixConfig(data[1]);

                deferred.resolve(true);
            }

            function fixConfig(conf) {
                return _.assign(conf, {
                    is_long_value: _.isBoolean(conf['is_long_value']) ? conf['is_long_value'] : conf['is_long_value'] == 'true'
                })
            }

            return deferred.promise;
        }

        function updateGroup(title, dict) {
            const deferred = $q.defer();
            const query = _.chain(dict).head().assign({id: dictionary.id}).value();

            mnWebSocket.call('shared.Dictionary.update_group', query)
                .then(success, deferred.reject);

            function success(data) {
                storageService.removeKey("user-dictionary");
                dictionary = data;
                deferred.resolve(getGroup(title));
            }

            return deferred.promise;
        }

        function updateModels(title, model) {
            const deferred = $q.defer();
            const obj = {title: title, model: model, id: dictionary.id};

            mnWebSocket.call('shared.Dictionary.update_model', obj)
                .then(success, deferred.reject);

            function success(data) {
                dictionary = data;
                deferred.resolve(getModels(title));
            }

            return deferred.promise;
        }

        function removeModel(title, index) {
            const deferred = $q.defer();
            const query = {title: title, index: index, id: dictionary.id};

            mnWebSocket.call('shared.Dictionary.remove_model', query)
                .then(success, deferred.reject);

            function success(data) {
                dictionary = data;
                deferred.resolve(getModels(title));
            }

            return deferred.promise;
        }

        function editDictionaryConfig(config) {
            const deferred = $q.defer();

            configService.set({"dictionary_config": config}, true)
                .then(success, deferred.reject);

            function success() {
                self.dictionaryConfig = config;
                deferred.resolve(true);
            }

            return deferred.promise;
        }


        function editDictionaryGroup(node) {
            const deferred = $q.defer();
            const query = _.chain(node).pick(['value', 'uid', 'nodes', 'is_deleted']).assign({id: dictionary.id}).value();

            mnWebSocket.call('shared.Dictionary.update_group', query)
                .then(success, deferred.reject);

            function success(data) {
                storageService.removeKey("user-dictionary");
                dictionary = data;
                deferred.resolve(true);
            }

            return deferred.promise;
        }

        function prepareDictionary(group, parent = false) {
            _.forEach(group.nodes, (node) => {
                if (!parent) node.parent = `${handleValue(group.parent)}${handleValue(group.value)}${self.dictionaryConfig['long_value_separator']}`;
                else node.parent = "";

                prepareDictionary(node);
            });

            return _.castArray(group);

            function handleValue(value) {
                return value ? value : '';
            }
        }

        function getDictionaryGroups() {
            return _.chain(dictionary.groups)
                .values()
                .filter(item => !_.get(item, 'is_deleted', false))
                .map(item => _.pick(item, ['value', 'uid']))
                .value();
        }

        function getDictionaryID() {
            return dictionary.id;
        }

        function getFieldHistory(patient, parent, uid, ev = null) {
            if (_.has(patient, "id")) patient = patient.id;

            const deferred = $q.defer();

            mnWebSocket.call("shared.blocks.HtmlBlock.field_history", {patient, parent, uid})
                .then(items => {
                    const dialog = _.assign({}, DICTIONARY_HISTORY_DIALOG, {
                        targetEvent: ev,
                        locals: {
                            items
                        }
                    });

                    $mdDialog.show(dialog)
                        .then(selected => {
                            deferred.resolve(selected);
                        }, deferred.reject);
                }, _.noop);

            return deferred.promise;
        }
    }

})();
