/**
 * Created by amine on 22/03/2017. // edited 28/01/2020
 */

(function () {
    'use strict';

    const BIRTH_VALIDATION_DIALOG = require('specifics/dialogs/birth-validation-dialog/birth-validation-dialog')

    const INIT_PREGNANCY = {
        amenorrhea_start: undefined,
        calculated_pregnancy_date: undefined,
        estimated_pregnancy_date: undefined,
        delivery_date: undefined,
        actual_term: undefined,
        actual_month: undefined,
        actual_day: undefined,
        details: [],
        visits: [],
        biology_prescriptions: [],
    };

    class PregnancyCtrl {
        constructor($q, scope, system, $element, $translate, $mdDialog, patientService, pregnancyService, configService, authService, frontDeskService, moment) {
            this.dateFormat = system.date_format.js;

            this.$q = $q;
            this.scope = scope;
            this.element = $element;
            this.translate = $translate;
            this.dialog = $mdDialog;
            this.patientService = patientService;
            this.pregnancyService = pregnancyService;
            this.configService = configService;
            this.authService = authService;
            this.frontDeskService = frontDeskService;
            this.moment = moment;

            this.promise = null;
            this.amenorrheaStartMax = this.moment().toDate();
            this.amenorrheaStartMin = this.moment().subtract(9, 'months').toDate();// new Date
            this.saving = false;
            this.savingConsultation = false;
            this.blockConfig = {};
            this.fields = [];
            this.currentVersion = -1;
            this.currentDate = null;
            this.patient = this.patient || null;
            this.medicalFile = {};
            this.pregnancy = this.pregnancy || INIT_PREGNANCY;
            this.pregnancies = this.pregnancies || [];
            this.postSaveSubject = this.postSaveSubject || null;
            this.readOnly = this.readOnly || false;
            this.postSave = this.postSave || _.noop;

            this.selectedComment = null;
        }

        static get $inject() {
            return ["$q", "$scope", "system", "$element", "$translate", "$mdDialog", "patientService", "pregnancyService", "configService", "authService", "frontDeskService", "moment"];
        }

        $onInit() {
            this.element.addClass("flex-noshrink").addClass("layout-column");

            this.newPregnancy();

            if (!_.isNil(this.postSaveSubject) && !this.readOnly) this.postSaveSubject
                .subscribe(next => this.consultationSaved(next));

            //this.patient = _.isObject(this.pregnancy.patient) ? _.get(this.pregnancy, "patient.id") : _.get(this.pregnancy, "patient.id");
            this.promise = this.$q.all([
                this.configService.getByHttp("pregnancy_block"),
                this.pregnancyService.getCalendar(),
                this.pregnancyService.get(this.patient),
                this.patientService.getActiveMedicalFile(this.patient)
                // this.pregnancyService.getLast(this.pregnancy.patient),
            ]).then(data => this.preparePregnancies(data), _.noop);

            this.validatePregnancy = () => this._validationPregnancy(true)();
            this.invalidatePregnancy = () => this._validationPregnancy(false)();
            this.handleAutoSave = _.mnDelay(() => this._handleAutoSave(), 750);

            this.delayedSave = _.mnDelay(() => {
                if (this.pregnancy.id) this.promise = this.submit();
            }, 750);
        }

        reloadPregnancies() {
            this.promise = this.$q.all([
                this.pregnancyService.get(_.get(this.pregnancy, "patient", this.patient))
            ]).then(data => this.preparePregnancies(data), _.noop)
        }

        reloadMedicalFile() {
            this.patientService.getActiveMedicalFile(this.patient)
                .then(data => this.medicalFile = data);
        }

        preparePregnancies(data) {
            if (data.length === 1) {
                this.pregnancies = data[0];
            } else {
                this.blockConfig = data[0];
                this.blockCalendar = data[1];
                this.pregnancies = data[2];
                this.medicalFile = data[3];
            }

            this.standard_error = _.get(this.blockConfig, "standard_error") || 5;
            this.pregnancies = _.map(this.pregnancies, pregnancy => this.pregnancyService.calculateFields(pregnancy, this.blockConfig, this.blockCalendar));
            if (this.pregnancies.length > 0) this.currentVersion = this.getCurrentPregnancy();
            if (this.currentVersion) this.newPregnancy(); // look at lines 121,122

            this.pregnancySelected();
            this.dictionaryFields();

            this.pregnancyService.medicalFileChanged.subscribe(next => {
                if (next) this.reloadMedicalFile();
            });
        }

        getCurrentPregnancy() {
            // for fix issue https://project.internal.selfip.com/projects/soft-factory/work_packages/2378/activity?query_id=173
            // we will return a new pregnancy if the last is validated (by system or user)
            let tmpCurrentVersion = _.chain(this.pregnancies)
                .orderBy("id", "desc")
                .findIndex(item => {
                    let itemDate = {};
                    let current = this.moment(this.currentDate, this.dateFormat);

                    if (!_.isNil(item.amenorrhea_start)) itemDate = this.moment(item.amenorrhea_start, this.dateFormat);
                    else if (!_.isNil(item.estimated_pregnancy_date)) itemDate = this.moment(item.estimated_pregnancy_date, this.dateFormat);

                    return (itemDate.isSameOrBefore(current, 'day') && !item.is_validated);
                })
                .value();


            return tmpCurrentVersion;
        }


        recalculate() {
            this.pregnancy = this.pregnancyService.calculateFields(this.pregnancy, this.blockConfig, this.blockCalendar);
            this.delayedSave();
        }

        next() {
            this.currentVersion -= 1;
            this.pregnancySelected();
        }

        previous() {
            this.currentVersion += 1;
            this.pregnancySelected();
        }

        pregnancySelected() {
            if (this.currentVersion === -1) this.newPregnancy();
            else this.pregnancy = this.pregnancies[this.currentVersion];

            if (!!this.pregnancy.amenorrhea_start && this.pregnancy.post_term && !this.pregnancy.is_validated) {
                // @todo a dialog should popup, for the user to enter the result of the pregnancy, (2.0.5) NOT REVIEWED
                //  for now to fix issue the https://project.internal.selfip.com/projects/soft-factory/work_packages/2378/activity?query_id=173
                // we will archive/validate automatically
                this.showBirthValidationDialog()
                    .then(data => {
                        this.pregnancy = _.assign(this.pregnancy, data);
                        this.delayedSave();
                    })
                //
            }
        }

        dictionaryFields() {
            this.fields = _.map(['observation', 'comment'], (item) => {
                return {
                    key: item,
                    label: this.translate["instant"](`pregnancy_${item}`),
                    meta_data: {
                        dict_uid: _.get(this.blockConfig, `dictionary.${item}`, null)
                    }
                }
            });
        }

        newPregnancy() {
            this.currentVersion = -1;

            this.pregnancy = _.assign({}, INIT_PREGNANCY, {
                patient: {
                    id: this.patient
                }
            })
        }

        deletePregnancy($event) {
            this.pregnancyService.deleteItem(this.pregnancy, $event)
                .then(() => {
                    _.remove(this.pregnancies, ['id', this.pregnancy.id]);

                    this.scope.$applyAsync(() => {
                        this.pregnancy = null;
                        this.newPregnancy();
                    });
                }, _.noop);
        }

        submit() {
            if (!this.pregnancy.is_validated && this.pregnancy.post_term)
                return this.validatePregnancy();

            if (_.isNil(this.pregnancy.real_delivery_date)) return this.save();
            else return this.validatePregnancy();
        }

        save() {
            if (_.isNil(this.pregnancy.amenorrhea_start) && _.isNil(this.pregnancy.estimated_pregnancy_date))
                return this.$q.reject(false);

            this.saving = true;
            this.promise = this.pregnancyService.save(this.pregnancy)
                .then(data => {
                    this.pregnancy = this.pregnancyService.calculateFields(data, this.blockConfig, this.blockCalendar);
                    _.pushOrUpdate(this.pregnancies, this.pregnancy);

                    const index = _.findIndex(this.pregnancies, ["id", this.pregnancy.id])

                    this.pregnancyService.medicalFileChanged.next(false);
                    this.currentVersion = index;
                    this.pregnancySelected();

                    if (!this.savingConsultation) {
                        this.postSave();
                    } else {
                        this.savingConsultation = false;
                    }
                }, _.noop);

            return this.promise;
        }

        _handleAutoSave() {
            if (this.medicalFile['created_at'] !== this.moment().format(this.dateFormat)) {
                this.medicalFile = _.chain(this.medicalFile)
                    .omit('id')
                    .assign({patient: this.patient})
                    .value();
            }

            this.patientService.handleMedicalFile(this.medicalFile, this.patient)
                .then(() => this.pregnancyService.medicalFileChanged.next(true));
        }

        _validationPregnancy(validate) {
            return () => {
                this.pregnancy.is_validated = validate;
                this.pregnancy.validate_at = validate ? this.moment() : null;

                return this.save();
            }
        }

        consultationSaved() {
            if (!this.saving && !_.isNil(this.pregnancy.amenorrhea_start) && !_.isNil(this.pregnancy.estimated_pregnancy_date)) {
                this.savingConsultation = true;
                this.save();
            } else this.saving = false;
        }

        setAppointment(item) {
            if (item.is_event) {
                const defaultReason = _.get(this.configService.defaultValues, "entry.reason", null);
                const reason = _.find(this.frontDeskService.reasonSubject.getValue(), ['id', _.get(item, "reason.id")]) || defaultReason;

                const defaultPhysician = _.get(this.configService.defaultValues, "physician", null);
                const physician = this.authService.staff || defaultPhysician;

                item.provisional_date = item.planned_date;

                item.appointment = {
                    reason,
                    physician,
                    patient: this.pregnancy.patient,
                    is_waiting_list: true,
                    waiting_list_comment: this.translate.instant('pregnancy_appointment_comment_w_date', {
                        date: item.planned_date,
                        title: item.title
                    }),
                };

                this.delayedSave();
            }
        }

        openAppointmentDialog(item, index) {
            const deferred = this.$q.defer();

            if (item.is_event) {
                this.patientService
                    .getMinimalPatient(this.patient)
                    .then((patient) => {
                        let event = {};
                        let appointmentDate = item.expected_date;

                        if (_.get(item.appointment, "date")) appointmentDate = item.appointment.date;
                        else if (item.planned_date) appointmentDate = item.planned_date;

                        if (_.has(item, 'appointment.id')) {
                            event = {
                                id: item.appointment.id,
                                assignIn: {
                                    date: appointmentDate,
                                    //is_waiting_list: false
                                }
                            }
                        } else {
                            const defaultReason = _.get(this.configService.defaultValues, "entry.reason", null);
                            const reason = _.find(this.frontDeskService.reasonSubject.getValue(), ['id', _.get(item, "reason.id")]) || defaultReason;

                            event = {
                                date: appointmentDate,
                                start_time: "09:00",
                                end_time: `09:${_.get(reason, 'appointment_duration', 10)}`,
                                patient: patient,
                                waiting_list_comment: item.title,
                                reason
                            }
                        }

                        deferred.resolve(event);
                    });
            } else {
                deferred.resolve(false);
            }

            return deferred.promise;
        }

        // afterAppointment() {
        //     ;
        // }

        editAppointment(item, index) {
            return this.openAppointmentDialog(item, index);
        }

        itemValidation(item) {
            item.is_done = !item.is_done;
            setTimeout(() => {
                this.save();
            }, 500);
        }

        commentLine(item) {
            if (this.selectedComment === item.uid) this.selectedComment = null;
            else this.selectedComment = item.uid;
        }

        emptyRDeliveryDate() {
            this.pregnancy.real_delivery_date = null;
        }

        showBirthValidationDialog(ev) {
            return this.dialog.show(_.assign({}, BIRTH_VALIDATION_DIALOG, {
                targetEvent: ev,
                locals: {
                    model: {
                        real_delivery_date: !!this.pregnancy.real_delivery_date ? this.pregnancy.real_delivery_date : this.moment().format(this.dateFormat),
                        birth_type: this.pregnancy.birth_type
                    }
                }
            }));
        }

        birthTypeChanged() {
            if (!this.pregnancy.real_delivery_date) {
                this.pregnancy.real_delivery_date = this.moment().format(this.dateFormat);
                this.delayedSave();
            }
        }

        realDeliveryDateChanged() {
            if (!!this.pregnancy.real_delivery_date) this.delayedSave();
        }
    }

    module.exports = {
        bindings: {
            patient: '<?patient',
            postSave: '&?postSave',
            postSaveSubject: '<?postSaveSubject',
            readOnly: '<?readonly',
            currentDate: '<?',
        },
        template: require("./pregnancy-block.tpl.html"),
        controllerAs: "vm",
        controller: PregnancyCtrl,
    };


})();
