/**
 * Created by amine on 08/09/2017.
 */

(function () {
    "use strict";

    const {AsyncSubject} = require("rxjs");

    const DataInjector = require("../plugins/data-injector");
    const SpeechRecognitionCapability = require("../plugins/speech-recognition-capability");
    const ContextualModelManager = require("../plugins/contextual-model-manager");

    const TEXT_EDITOR_CONFIG = require('../json/text-editor.json');
    const {DEFAULT_KEY_HANDLERS} = require("../utils/consts");
    const CONTEXTS = {
        "minimal": require('../json/minimal-text-editor.json'),
        "email": require("../json/email-text-editor.json"),
        "consent": require("../json/consent-text-editor.json"),
        "video-call": require("../json/video-call-text-editor.json"),
        "reminder-email": require("../json/reminder-email-text-editor.json"),
    };

    const {
        compile, compileContent, resetMenus, getModel
    } = require("../utils/editor-functions");

    const calculateFormula = require('shared/utils/calculate-formula');

    class EditorConfig {
        constructor(editorInstance) {
            this.editorInstance = editorInstance;
            this.customMenus = false;
        }

        get #options() {
            return this.editorInstance.options;
        }

        get #externalConfig() {
            return this.editorInstance.externalConfig;
        }

        get #isMinimal() {
            return !!this.#options.minimal;
        }

        get #context() {
            if (this.#isMinimal) {
                return "minimal";
            }

            return this.#options.context;
        }

        set #context(value) {
            this.#options.context = value
        }

        generate(deferred) {
            const contextConfig = _.get(CONTEXTS, this.#context, {})
            const dataInjector = new DataInjector(this.editorInstance);
            new SpeechRecognitionCapability(this.editorInstance);
            const contextualModels = new ContextualModelManager(this.editorInstance);
            const dynamicToolbar = dataInjector.setMenus(_.concat([this.#patientMenu(), this.#measurementMenu(), this.#physicianMenu(), this.#definedBlockMenu(), this.#reminderEmailMenu(), this.#videoCallMenu(), this.#consentMenu(), this.#sportDataMenu(), this.#technicalFileMenu(), this.#ftPacsStudiesMenu()], !!this.customMenus ? this.customMenus : []));

            // if (options['has_fire_pacs_exams_sharing'] && options.fire_pacs_studies.length > 0) {
            //     toolbar.data_injector_buttons.push(FirePacsStudiesMenu(options));
            //     toolbar.toolbar1 += "fire_pacs_exams_data_menu ";
            // }

            const dynamicConfig = {
                language: this.editorInstance.lang,
                keyHandlers: DEFAULT_KEY_HANDLERS,
                template: "",
                setup: (editor) => {
                    if (_.isFunction(this.#externalConfig.onSetup)) this.#externalConfig.onSetup(editor);

                    if (_.isFunction(this.#options.onSetup)) this.#options.onSetup(editor);

                    contextualModels.setContext(this.#options.contextualModelsContext)
                },
                init_instance_callback: editor => {
                    editor.__meta__ = {
                        options: this.#options, config: this.#externalConfig
                    };

                    if (!this.#options.minimal) {
                        editor.compile = content => compile(editor, content);
                        editor.compileContent = () => compileContent(editor);
                        editor.resetMenus = () => resetMenus(editor);
                        editor.getModel = () => getModel(editor);

                        editor.plugins['data_injector'].handleMnVarClick();
                        editor.plugins['speech_recognition'].afterEditorInit();
                    }

                    this.editorInstance.addMenusSubject.complete();
                    this.editorInstance.addMenusSubject = new AsyncSubject();

                    deferred.resolve(editor);

                    if (_.isFunction(this.#options.onInit)) this.#options.onInit(editor);
                },
                file_picker_callback: (cb, value, meta) => {
                    let input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('accept', 'image/*');

                    input.onchange = function () {
                        let file = this.files[0];

                        let reader = new FileReader();
                        reader.onload = function () {
                            // Note: Now we need to register the blob in TinyMCEs image blob
                            // registry. In the next release this part hopefully won't be
                            // necessary, as we are looking to handle it internally.
                            const id = 'blobid' + (new Date()).getTime();
                            const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                            const base64 = reader.result.split(',')[1];
                            const blobInfo = blobCache.create(id, file, base64);
                            blobCache.add(blobInfo);

                            // call the callback and populate the Title field with the file name
                            cb(blobInfo.blobUri(), {title: file.name});
                        };
                        reader.readAsDataURL(file);
                    };

                    input.click();
                }
            };

            const fullConfig = _.assign(dynamicConfig, TEXT_EDITOR_CONFIG, contextConfig, this.#externalConfig);

            if (!!dynamicToolbar && !this.#options.minimal) {
                fullConfig.toolbar = fullConfig.toolbar.replace("data_injector_menus", dynamicToolbar)
            }

            return fullConfig;
        }

        #getValue(instance, key, defaultValue = null) {

            if (this.#options.isModel || !instance) {
                return () => {
                    return defaultValue;
                }
            } else {
                return () => {
                    let value = _.get(instance, key, defaultValue);
                    return _.isNil(value) ? defaultValue : value;
                };
            }
        }

        #patientMenu() {
            return {
                title: "patient_editor_data", dynamic: true, show: this.#options.has_patient_menu, fetch: () => {
                    const deferred = this.editorInstance.q.defer();
                    this.editorInstance.q.all([this.#options.patient ? this.editorInstance.patientService.getFormPatient(this.#options.patient) : this.editorInstance.q.when(null)]).then(data => {
                        const has_parents_name = this.editorInstance.configService.activeModules['has_parents_name']
                        const patient = _.has(data[0], "id") ? data[0] : null;

                        let items = [{
                            title: "file_number",
                            value: this.#getValue(patient, "file_number"),
                            key: "patient::file_number"
                        }, {
                            title: "full_name", value: this.#getValue(patient, "full_name"), key: "patient::full_name"
                        }, {
                            title: "last_name", value: this.#getValue(patient, "last_name"), key: "patient::last_name"
                        }, {
                            title: "first_name",
                            value: this.#getValue(patient, "first_name"),
                            key: "patient::first_name"
                        }, {
                            title: "birth_date",
                            value: this.#getValue(patient, "birth_date"),
                            key: "patient::birth_date"
                        }, {
                            title: "age", value: this.#getValue(patient, "age"), key: "patient::age"
                        }, {
                            title: "address",
                            value: this.#getValue(patient, "contact_info.address"),
                            key: "patient::address"
                        }, {
                            title: "city",
                            value: this.#getValue(patient, "contact_info.city.full_name"),
                            key: "patient::city"
                        }, {
                            title: "phone_number",
                            value: this.#getValue(patient, "contact_info.phone_numbers[0]"),
                            key: "patient::phone_number"
                        }, {
                            title: "profession",
                            value: this.#getValue(patient, "profession.value"),
                            key: "patient::profession"
                        }, {
                            title: "national_id",
                            value: this.#getValue(patient, "national_id"),
                            key: "patient::national_id"
                        }, {
                            title: "treating_physician",
                            value: this.#getValue(patient, "external_treating_physician.full_name"),
                            key: "patient::treating_physician"
                        }, {
                            title: "file_date", value: this.#getValue(patient, "file_date"), key: "patient::file_date"
                        }];

                        if (has_parents_name) {
                            items = _.concat(items, [
                                {
                                    title: "father_fullname",
                                    value: this.#getValue(patient, "father_name"),
                                    key: "patient::father_name"
                                }, {
                                    title: "mother_fullname",
                                    value: this.#getValue(patient, "mother_name"),
                                    key: "patient::mother_name"
                                }
                            ])
                        }

                        deferred.resolve(items);
                    });

                    return deferred.promise;
                }
            }
        }

        #getMeasurement(measure, measurements, measures) {
            if (this.#options.isModel || _.isNull(measurements)) {
                return null;
            } else {
                return () => {
                    const unit = !_.isNull(measure.unit) ? (" " + measure.unit.value) : "";
                    const measurement = _.get(measurements, measure.id, {});

                    if (_.isEmpty(measurement) && measure.type !== "calculated") {
                        return `- ${unit}`;
                    } else {
                        switch (measure.type) {
                            case "integer":
                                return `${measurement.value.toFixed(0)} ${unit}`;
                            case "float":
                                return `${measurement.value.toFixed(2)} ${unit}`;
                            case "boolean":
                                return measurement.value ? this.editorInstance.translate.instant("yes") : this.editorInstance.translate.instant("no");
                            case "calculated":
                                const calculatedValue = calculateFormula(measure.formula, measurements, measures);
                                return `${calculatedValue ? calculatedValue.toFixed(2) : "-"} ${unit}`;
                            default:
                                return `${measurement.value} ${unit}`;
                        }
                    }
                }
            }
        }

        #measurementMenu() {
            return {
                title: "patient_measurements_editor_data",
                dynamic: true,
                show: this.#options.has_measurement_menu,
                fetch: () => {
                    const deferred = this.editorInstance.q.defer();

                    this.editorInstance.q.all({
                        measures: this.editorInstance.measureService.getMeasures(),
                        measurements: this.#options.patient ?
                            this.editorInstance.measureService.getMeasurementResume({
                                patient_id: this.#options.patient,
                                with_measures: false
                            }) :
                            this.editorInstance.q.when(null)
                    }).then(({measures, measurements}) => {
                            measurements = _.get(measurements, 'measurements');

                            deferred.resolve(_.reduce(measures, (result, value) => {
                                result.push({
                                    title: value.name,
                                    value: this.#getMeasurement(value, measurements, measures),
                                    key: `measurements::measure${value.id}`
                                })

                                return result;
                            }, []));
                        }
                    )
                    ;

                    return deferred.promise;
                }
            }
        }

        #physicianMenu() {
            let physician = _.get(this.editorInstance.authService, "staff", false);

            return {
                title: "physician_editor_data", dynamic: true, show: this.#options.has_physician_menu, items: [{
                    title: "full_name", value: this.#getValue(physician, "full_name"), key: "physician::full_name"
                }]
            }
        }

        #getVaccinations(vaccinations) {
            return () => {
                if (!!vaccinations) {
                    const $el = $("<div />").append($("<ul />"));

                    vaccinations.forEach(vaccination => {
                        const li = `<li>
                                    <span style='text-decoration: underline;font-weight: bold;'>${vaccination.vaccine_name}: ${vaccination.vaccination_date}(${vaccination.age_at_vaccination})</span>
                                </li>`;

                        $("ul", $el).append(li);

                        return $el.html();
                    });
                } else {
                    return "-";
                }
            }
        }

        #sportDataMenu() {
            return {
                title: "sport_data_editor",
                label: "sport_data.tab_title",
                dynamic: true,
                show: _.get(this.editorInstance.configService.activeModules, "has_sport_data"),
                fetch: () => {
                    const deferred = this.editorInstance.q.defer();
                    (this.#options.patient ? this.editorInstance.sportDataService.getActiveSportFile(this.#options.patient) : this.editorInstance.q.when(null)).then(data => {
                        deferred.resolve([{
                            title: "license_number", label: "sport_data.license_number", value: () => {
                                return _.get(data, "license_number", "-");
                            }, key: "sport_data::license_number"
                        }, {
                            title: "club_name", label: "sport_data.is_team", value: () => {
                                return _.get(data, "team.organization.name", "-");
                            }, key: "sport_data::club_name"
                        }, {
                            title: "club_category", label: "sport_data.club_category", value: () => {
                                return _.get(data, "team.category.value", "-");
                            }, key: "sport_data::club_category"
                        }, {
                            title: "club_abbr", label: "sport_data.club_abbr", value: () => {
                                return _.get(data, "team.organization.short_name", "-");
                            }, key: "sport_data::club_abbr"
                        }, {
                            title: "nt_name", label: "sport_data.is_national_team", value: () => {
                                return _.get(data, "national_team.organization.name", "-");
                            }, key: "sport_data::club_name"
                        }, {
                            title: "nt_category", label: "sport_data.nt_category", value: () => {
                                return _.get(data, "national_team.category.value", "-");
                            }, key: "sport_data::nt_category"
                        }, {
                            title: "nt_abbr", label: "sport_data.national_team_abbr", value: () => {
                                return _.get(data, "national_team.organization.short_name", "-");
                            }, key: "sport_data::nt_abbr"
                        }]);
                    });

                    return deferred.promise;
                }
            }
        }

        #getCustomFieldValue(instance, field, defaultValue = "-") {
            if (this.#options.isModel || !instance || !_.has(field, "type")) {
                return () => defaultValue;
            } else {
                return () => {
                    let value = null;
                    switch (field.type) {
                        case "input":
                        case "textarea":
                            value = _.get(instance, field.slug, defaultValue);
                            break;
                        case "date":
                            value = _.get(instance, field.slug, defaultValue);
                            switch (field.format) {
                                case "day":
                                    break;
                                case "month":
                                    value = this.editorInstance.moment(value, this.editorInstance.dateFormat);
                                    value = value.isValid() ? value.format("MM/YYYY") : defaultValue;
                                    break;
                                case "year":
                                    value = this.editorInstance.moment(value, this.editorInstance.dateFormat);
                                    value = value.isValid() ? value.format("YYYY") : defaultValue;
                                    break;
                            }
                            break;
                        case "custom-select":
                            value = _.get(instance, `${field.slug}.value`, defaultValue);
                            break;
                        case "switch":
                            value = !!_.get(instance, `${field.slug}`, defaultValue) ? this.editorInstance.translate.instant("yes") : this.editorInstance.translate.instant("no");
                            break;
                    }
                    return _.isNil(value) ? defaultValue : value;
                };
            }
        }

        #technicalFileMenu() {
            return {
                title: "technical_file_editor",
                label: "technical_file.tab_title",
                dynamic: true,
                show: _.get(this.editorInstance.configService.activeModules, "has_technical_file"),
                fetch: () => {
                    const deferred = this.editorInstance.q.defer();
                    this.editorInstance.q.all([(this.#options.patient ? this.editorInstance.technicalFileService.getActiveFile(this.#options.patient) : this.editorInstance.q.when(null)), this.editorInstance.configService.getByHttp('technical_file_fields')]).then(([file, fields]) => {
                        deferred.resolve(_.reduce(fields, (acc, field) => {
                            return _.concat(acc, {
                                title: field.slug,
                                label: field.label,
                                noTranslate: true,
                                value: this.#getCustomFieldValue(file.body, field),
                                key: `technical_file::${field.slug}`
                            })
                        }, []));
                    });

                    return deferred.promise;
                }
            }
        }

        #definedBlockMenu() {
            return {
                title: "plus_editor_data", dynamic: true, show: this.#options.has_misc_menu, fetch: () => {
                    const deferred = this.editorInstance.q.defer();
                    (this.#options.patient ? this.editorInstance.patientService.getVaccinations(this.#options.patient) : this.editorInstance.q.when([])).then(data => {
                        deferred.resolve([{
                            title: "date", value: () => {
                                return this.editorInstance.moment().format(this.editorInstance.dateFormat);
                            }, key: "misc::date"
                        }, {
                            title: "patient_vaccination",
                            value: this.#getVaccinations(data),
                            key: "defined::vaccinations"
                        }]);
                    });

                    return deferred.promise;
                }
            }
        }

        #reminderEmailMenu() {
            return {
                title: "reminder_email", items: [{
                    title: "full_name", value: null, key: "patient::fullname"
                }, {
                    title: "appointment_date", value: null, key: "appointment::date"
                }, {
                    title: "appointment_time", value: null, key: "appointment::time"
                }]
            };
        }

        #videoCallMenu() {
            return {
                title: "video_call", items: [{
                    title: "full_name", value: null, key: "patient_full_name"
                }, {
                    title: "physician_name", value: null, key: "physician_full_name"
                }, {
                    title: "video_call_url", value: null, key: "video_call_url"
                }, {
                    title: "video_call_password", value: null, key: "video_call_password"
                }]
            };
        }

        #consentMenu() {
            return {
                title: "consent", items: [{
                    title: "full_name", value: null, key: "patient::fullname"
                }, {
                    title: "birth_date", value: null, key: "patient::birth_date"
                }, {
                    title: "age", value: null, key: "patient::age"
                }, {
                    title: "|"
                }, {
                    title: "signature", value: null, key: "document::signature"
                }, {
                    title: "signature_date", value: null, key: "document::signature_date"
                },]
            };
        }

        /*
        * const items = _.reduce(options.fire_pacs_studies, (result, value) => {
            const item = {
                title: value.study_description,
                items: [
                    {
                        title: 'fire_pacs_exams_study_url',
                        kay: `${value.password}::url`,
                        value: () => value.external_url
                    },
                    {
                        title: 'fire_pacs_exams_study_qr_code',
                        kay: '`${value.password}::qr`',
                        value: () => value.qr_code,
                        isImage: true
                    },
                    {
                        title: 'fire_pacs_exams_study_password',
                        kay: '`${value.password}::password`',
                        value: () => value.password
                    }
                ]
            }

            return _.concat(result, item);
        }, []);

        return {
            title: '', items
        }

        *
        * */

        #ftPacsStudiesMenu() {
            return {
                title: "fire_pacs_exams_data",
                dynamic: true,
                show: this.#options.has_fire_pacs_exams_sharing,
                fetch: () => {
                    const deferred = this.editorInstance.q.defer();

                    (this.#options.patient ? this.editorInstance.dcmService.getFirePacsSharedStudies(this.#options.patient) : this.editorInstance.q.when(null))
                        .then(studies => {

                            // const measures = data[0];
                            // const measurements = data[1];
                            //
                            deferred.resolve(_.reduce(studies, (result, value) => {
                                result.push({
                                    title: !!value.study_description ? value.study_description : value.study_id,
                                    noTranslate: true,
                                    submenuItems: [{
                                        title: 'fire_pacs_exams_study_url',
                                        key: `${value.study_id}::url`,
                                        value: () => value.external_url
                                    }, {
                                        title: 'fire_pacs_exams_study_qr_code',
                                        kay: '`${value.password}::qr`',
                                        value: () => value.qr_code,
                                        isImage: true,
                                        alt: `${value.study_id} QRCode`
                                    }, {
                                        title: 'fire_pacs_exams_study_password',
                                        key: `${value.study_id}::password`,
                                        value: () => value.password
                                    }]
                                    // value: () => value.external_url,
                                    // key: `${value.password}::url`
                                })

                                return result;
                            }, []));
                        });

                    return deferred.promise;
                }
            }
        }
    }

    module.exports = EditorConfig;
})();
