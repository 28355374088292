(function () {

    'use strict';

    class AdvancedFilterDialogCtrl {
        constructor($mdDialog, patientService, configService, moment, system) {
            this.patientService = patientService;
            this.configService = configService;
            this.dialog = $mdDialog;
            this.moment = moment;
            this.dateFormat = system['date_format'].js;

            this.technicalFileFields = [];
        }

        static get $inject() {
            return ["$mdDialog", "patientService", "configService", "moment", "system"];
        }

        get customFields() {
            return this.patientService.customFields;
        }

        $onInit() {
            this.model = _.clone(this.model || {});
            this.hasSportData = _.get(this.configService.activeModules, "has_sport_data");
            this.hasTechnicalFile = _.get(this.configService.activeModules, "has_technical_file");

            if (this.hasTechnicalFile) {
                this.configService.getByHttp('technical_file_fields')
                    .then(data => {
                        this.technicalFileFields = data;
                    });
            }
        }

        submit() {
            const subFields = ["patient", "custom_fields", "sport_data", "technical_file"]
            const subQueries = _.map(subFields, key => {
                return this.#formatData(key, _.get(this.model, key, {}))
            });

            this.dialog.hide({
                model: this.model,
                query: _.zipObject(subFields, subQueries)
            });
        }

        #formatData(subField, data) {
            return _.reduce(data, (acc, item, key) => {
                if (!item) return acc;

                if (subField === "patient") {
                    if (["gender", "bd_start", "bd_end"].includes(key)) {
                        acc[key] = item;
                        return acc;
                    }
                }

                if (_.has(item, "id")) acc[key] = _.pick(item, "id");
                else if (_.isString(item)) acc[key] = {$regex: `.*${item}.*`, $options: "i"};
                else if (_.isArray(item)) {
                    acc[key] = {$in: _.map(item, "id")}
                }

                return acc;
            }, {});
        }

        handleStartDateChange() {
            this.#handleDateChange(true);
        }

        handleEndDateChange() {
            this.#handleDateChange();
        }

        #handleDateChange(isStart = false) {
            const start = this.moment(this.model.patient.bd_start, this.dateFormat);
            const end = this.moment(this.model.patient.bd_end, this.dateFormat);

            if (isStart) {
                if (end.isValid() && end.isBefore(start)) {
                    this.model.patient.bd_end = start.format(this.dateFormat);
                }
            } else {
                if (end.isBefore(start)) {
                    this.model.patient.bd_start = end.format(this.dateFormat);
                }
            }
        }

        clear() {
            this.model = {};
        }

        cancel() {
            this.dialog.cancel();
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: AdvancedFilterDialogCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("./advenced-filter-dialog.tpl.html"),
    };

})();
