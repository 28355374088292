/**
 * Created by BETALOS on 14/06/2016.
 */
(function () {

    'use strict';

    const {Subject} = require("rxjs");
    const MODEL_DIALOG = require('../dialogs/dictionary-models-dialog');
    const NEW_ENTRY_DIALOG = require('../dialogs/dictionary-new-entry-dialog');
    const CHIP_EDITION_DIALOG = require('../dialogs/dictionary-chip-edition-dialog');

    class DictionaryCtrl {
        constructor(dictionaryService, $element, $mdDialog, consultationService) {
            this.dialog = $mdDialog;
            this.$element = $element;
            this.dictionaryService = dictionaryService;
            this.consultationService = consultationService;

            this.model = [];
            this.field = this.field || null;
            this.blockUid = this.blockUid || null;
            this.patient = this.patient || null;
            this.readonly = this.readonly || false;
            this.ngModelController = this.ngModelController || null;

            this.listnerSubject = new Subject();
            this.listnerSubscription = this.listnerSubject.subscribe(items => this.handleNode(items));
        }

        static get $inject() {
            return ["dictionaryService", "$element", "$mdDialog", "consultationService"];
        }

        $onInit() {
            this.dictUid = _.get(this.field, 'meta_data.dict_uid', '');
            this.readOnly = _.isFunction(this.readonly) ? this.readonly() : false;

            this.ngModelController.$render = () => this.renderCurrentValue();

            this.$element.on('focusin', '.md-input', () => this.focus());
            this.$element.on('click', '.md-chips', ev => this.triggerFocus(ev));

            this.treeHandler = {
                title: this.dictUid,
                subject: this.listnerSubject
            }
        }

        $onDestroy() {
            this.$element.off('click', '.md-chips');
            this.$element.off('focusin', '.md-input');

            this.listnerSubject.complete();
            this.listnerSubscription.unsubscribe();
        }

        transformChip(chip) {
            if (_.isObject(chip)) return chip;
            else return {value: chip};
        }

        emptyField() {
            this.model = [];
            this.triggerChange();
        }

        triggerFocus(ev) {
            const $node = $(ev.currentTarget);
            if (!$node.is('.md-focused')) $('.md-input', this.$element).trigger('focus');
        }

        focus() {
            this.consultationService.lockSubject.next({lock: false});
            this.dictionaryService.dictModelSubject.next(this.treeHandler);
        }

        handleNode(nodes) {
            if (_.isArray(nodes)) nodes.forEach(node => this.pushValue(node));
            else this.pushValue(nodes);

            this.triggerChange();
        }

        pushValue(node) {
            this.model = _.concat(this.model || [], this.newChip(node));
        }

        newChip(node) {
            return {
                uid: node.uid,
                value: `${_.get(this.dictionaryService, 'dictionaryConfig.is_long_value') ? node.parent : ''}${node.value}`,
            }
        }

        viewModels(ev) {
            this.stopPropagate(ev);

            this.dialog.show(_.assign({}, MODEL_DIALOG, {
                locals: {
                    uid: this.dictUid,
                    modelsShown: true
                },
                targetEvent: ev
            })).then(nodes => this.handleNode(nodes));
        }

        addToDict(chip, ev) {
            this.stopPropagate(ev);

            this.dialog.show(_.assign({}, NEW_ENTRY_DIALOG, {
                targetEvent: ev,
                locals: {
                    uid: this.dictUid,
                    newEntry: chip
                }
            })).then(node => {
                let index = _.indexOf(this.model, chip);

                this.model.splice(index, 1, this.newChip(node));
                this.triggerChange();
            });
        }

        editChip(chip, ev) {
            this.stopPropagate(ev);

            this.dialog.show(_.assign({}, CHIP_EDITION_DIALOG, {
                targetEvent: ev,
                locals: {
                    field: 'value', chip: _.cloneDeep(chip)
                }
            })).then(node => {
                if (node.value !== chip.value) {
                    node.edited = true;
                }

                const index = _.indexOf(this.model, chip);
                this.model.splice(index, 1, _.omit(node, '$$hashKey'));
                this.triggerChange();
            });
        }

        triggerChange() {
            let viewValue = _.cloneDeep(this.model);

            this.ngModelController.$setViewValue(viewValue, 'change');
            this.ngModelController.$commitViewValue();
        }

        renderCurrentValue() {
            if (this.ngModelController.$viewValue !== this.model) {
                this.model = this.ngModelController.$viewValue || [];
            }
        }

        stopPropagate(ev) {
            ev.stopPropagation();
        }

        viewHistory(ev) {
            this.dictionaryService.getFieldHistory(this.patient, this.blockUid, this.field.key, ev)
                .then(data => {
                    if (data.replace) this.model = data.value;
                    else this.model = _.concat(this.model, data.value);
                }, _.noop);
        }
    }

    module.exports = {
        bindings: {
            readonly: "&?",
            field: "<mnField",
            labelColor: "@?",
            blockUid: "<parent",
            patient: "<"
        },
        controllerAs: "vm",
        controller: DictionaryCtrl,
        require: {ngModelController: "ngModel"},
        template: require('stand-alone/blocks/views/dictionary.tpl.html'), // or template
    };

})();
