/**
 * Created by amine on 28/06/2018.
 */
(function () {

    'use strict';

    module.exports = {
        controller: 'VaccinationCalendarSetupCtrl',
        controllerAs: "vm",
        template: require("./vaccination-config-container.tpl.html"),
        parent: $(document.body),
        locals: {
            dialogContext: true
        },
        bindToController: true,
        clickOutsideToClose: true,
        multiple: true
    };

})();