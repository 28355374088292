/**
 * Created by amine on 10/10/2022.
 */
(function () {
    'use strict';

    class EditorContextualTemplatesCtrl {
        constructor($mdDialog, editorContextualModelService, $scope, authService) {
            this.dialog = $mdDialog;
            this.service = editorContextualModelService;
            this.scope = $scope;

            this.isRoot = authService.isRootProfile();

            this.reset = null;
            this.promise = null;
            this.tinymceEditor = null;
            this.context = null;
            this.filter = {is_deleted: {$ne: true}};
            this.actions = {
                single: [
                    {
                        icon: 'mdi-receipt-text-check',
                        label: 'use',
                        method: (template, event) => this.#onSelectRow(template, event)
                    }
                ],
                multiple: []
            };
            this.selectRow = template => this.#onSelectRow(template, null);
        }

        static get $inject() {
            return ["$mdDialog", "editorContextualModelService", "$scope", "authService"];
        }

        $onInit() {
            if (!this.tinymceEditor) console.error("EDITOR INSTANCE IS UNDEFINED");

            if (!!this.context) {
                this.filter = {
                    is_deleted: {$ne: true},
                    $or: [{context: {$eq: {}}}, {context: this.context}]
                };
            }
            //this.tinymceEditor.
            //
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.dialog.hide();
        }

        #onSelectRow(template, event) {
            this.tinymceEditor.execCommand("mceInsertContent", false, template.content);
            this.dialog.hide(template);
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: EditorContextualTemplatesCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("./editor-contextual-templates.tpl.html"),
    };
})();