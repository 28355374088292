/**
 * Created by amine on 16/04/2018. edited 03/10/2022.
 */
(function () {

    'use strict';

    class VaccinationConfigDialogCtrl {
        constructor($mdDialog, vaccinationService) {
            this.dialog = $mdDialog;
            this.vaccinationService = vaccinationService;

            this.promise = null;
            this.vaccines = [];
            this.selectedVaccines = null;
        }

        static get $inject() {
            return ["$mdDialog", "vaccinationService"];
        }

        $onInit() {
            if (!this.config)
                this.config = {
                    vaccines: [],
                    injections: [{}],
                    boosters: [],
                    injections_count: 1,
                    boosters_count: 0,
                    gender: "UNDEFINED"
                };

            this.changeInjections = () => this.detailsChange("injections")();
            this.changeBoosters = () => this.detailsChange("boosters")();

            this.promise = this.vaccinationService.getVaccines()
                .then(data => this.vaccines = data);
        }

        addVaccine() {
            this.config.vaccines = _.chain(this.config.vaccines)
                .concat(this.selectedVaccines)
                .uniqBy('id')
                .filter(item => !_.isNil(item))
                .value();
            this.selectedVaccines = null;
        }

        removeVaccine(item) {
            _.remove(this.config.vaccines, _.pick(item, "id"));
        }

        detailsChange(name) {
            return () => {
                if (this.config[name + '_count'] > 0) {
                    this.config[name] = _.chain(0)
                        .range(this.config[name + '_count'])
                        .map(() => {
                            return {}
                        })
                        .value();
                }
            }
        }

        submit() {
            return this.vaccinationService
                .saveVaccinationConfig(this.config)
                .then(this.dialog.hide)
        }

        cancel() {
            this.dialog.cancel();
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: VaccinationConfigDialogCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("./vaccination-config-form.tpl.html"),
    };

})();