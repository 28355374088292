/**
 * Created by amine on 21/01/2020.
 */
(function () {

    'use strict';

    class BirthValidationDialogController {
        constructor($mdDialog, moment) {
            this.dialog = $mdDialog;
            this.moment = moment;

            this.model = null;
        }

        static get $inject() {
            return ["$mdDialog", "moment"];
        }

        $onInit() {
            this.maxDate = new Date();
            this.model = !!this.model ? _.clone(this.model) : {
                real_delivery_date: this.moment(),
                birth_type: null
            };
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.dialog.hide(_.clone(this.model));
        }
    }

    module.exports = {
        controllerAs: "vm",
        controller: BirthValidationDialogController,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        template: require("./birth-validation-dialog.tpl.html"),
    };


})();
