/**
 * Created by BETALOS on 18/12/2015.
 */
(function () {

    'use strict';

    class VisualizeFileCtrl {
        constructor($mdDialog, uploadService, printService, $element, $scope, $mdConstant, $q) {
            this.$scope = $scope;
            this.dialog = $mdDialog;
            this.$element = $element;
            this.q = $q;

            this.uploadService = uploadService;
            this.printService = printService;

            this.file = null;
            this.selectedFiles = [];

            this.up = $mdConstant.KEY_CODE.UP_ARROW;
            this.left = $mdConstant.KEY_CODE.LEFT_ARROW;
            this.down = $mdConstant.KEY_CODE.DOWN_ARROW;
            this.right = $mdConstant.KEY_CODE.RIGHT_ARROW;
        }

        static get $inject() {
            return ["$mdDialog", "uploadService", "printService", "$element", "$scope", "$mdConstant", "$q"];
        }

        $onInit() {
            this.files = this.files ? this.files : [];
            this.$element.on('keydown', (ev) => this.handlePress(ev));

            /* to work with amine modification (visualize-image-dialog) */
            this.onlyOne = _.isNil(this.onlyOne) ? false : this.onlyOne;
            this.allowEdit = _.isNil(this.allowEdit) ? true : this.allowEdit;
            this.hasCompare = _.isNil(this.hasCompare) ? true : this.hasCompare;
            this.allowDownload = _.isNil(this.allowDownload) ? true : this.allowDownload;
            /* to work with amine modification */

            if (!this.file) this.refreshFile();
        }

        $onDestroy() {
            this.$element.off('keypress');
        }

        cancel() {
            this.dialog.cancel();
        }

        refreshFile() {
            this.file = _.get(this.files, this.fileIndex);
        }

        handlePress(ev) {
            if (this.files.length === 0 || !_.includes([this.up, this.down, this.left, this.right], ev.keyCode) || this.editImage) return;

            if (_.includes([this.up, this.left], ev.keyCode) && this.fileIndex !== 0) this.previous();
            if (_.includes([this.down, this.right], ev.keyCode) && this.fileIndex !== this.files.length - 1) this.next();

            this.$scope.$applyAsync();
        }

        canCompare() {
            return _.chain(this.files)
                .filter(item => _.includes(item.mime, 'image'))
                .size()
                .value() < 2;
        }

        getFiles() {
            if (this.compare) return _.filter(this.files, item => _.includes(item.mime, 'image'));
            else return this.files;
        }

        isSelected(index) {
            if (this.compare) {
                let file = _.get(this.getFiles(), index);
                return _.includes(this.selectedFiles, file);
            } else return this.fileIndex === index;
        }

        next() {
            this.fileIndex += 1;
            this.refreshFile();
        }

        previous() {
            this.fileIndex -= 1;
            this.refreshFile();
        }

        selectItem(index) {
            if (this.compare) {
                let file = _.get(this.getFiles(), index);
                this.selectedFiles = _.chain(this.selectedFiles)
                    .pullAt(0).concat(file).value();
            } else {
                this.fileIndex = index;
                this.refreshFile();
            }
        }

        exitCompare() {
            this.compare = false;
            this.selectedFiles = [];
            this.refreshFile();

            setTimeout(() => {
                const event = new Event('resize');
                window.dispatchEvent(event);
            });
        }

        download() {
            this.promise = this.uploadService.downloadFile(this.file);
        }

        reloadFile() {
            const deferred = this.q.defer()
            this.file = null;
            this.promise = deferred.promise;
            setTimeout(() => {
                this.file = _.get(this.files, this.fileIndex);
                deferred.resolve(true);
            });
        }

        goToEditImage() {
            this.editImage = true;
        }

        printImage() {
            const url = this.file.url ? this.file.url : `/api/upload/${this.file.id}/download/`;
            this.printService.showPreview(url);
        }

        goToCompare() {
            this.compare = true;
            this.selectedFiles.push(this.file);
        }

        swipeLeft() {
            if (!this.editImage && !this.compare) this.next();
        }

        swipeRight() {
            if (!this.editImage && !this.compare) this.previous();
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: VisualizeFileCtrl,
        template: require('../views/visualize-file-dialog.tpl.html'),
    };

})();
