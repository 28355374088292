/**
 * Created by BETALOS on 14/07/2016.
 */
(function () {
    'use strict';

    const angular = require('angular');

    const {
        calendar,
        rdvCalendarElement,
        groupRdvCalendarElement,
        pauseCalendarElement,
        unavailableCalendarElement,
        CalendarService
    } = require('./calendar/');

    const {dcmService} = require('./dcm/');

    const {
        fileSize,
        uploadService,
        mnFiles,
        mnFileItem,
        mnFileVisualizer,
        mnPdfViewer,
        mnStlViewer,
        mnExcelViewer,
        filesFilter,
        mnImageEditor,
        mnImageComparison,
        mnContextualFiles
    } = require('./file-manager/');

    const {
        mnPatientSummery, mnVisitSummary, mnConsultationsListSummary, mnConsultationSummary, mnPrescriptionsListSummary,
        mnCertificatesListSummary, mnExamsListSummary, mnPatientSummaryLink
    } = require('./patient-summary/');

    const mnImageEdition = require('./image-edition/');

    const {interfacingService, formBlockField} = require('./interfacing/');

    // never user was just for test
    // const table = require('./table/');

    const {
        editorManagerService, customVariableService, editorContextualModelService, mnTextEditor, mnSMSEditor
    } = require('./text-editor/');

    const {
        mnAutocomplete,
        mnDictionary,
        mnFreeDictionary,
        mnHtmlBlock,
        mnFormHtmlBlock,
        mnDictTree,
        mnFileBlock,
        mnTree,
        surveyResume,
        surveyQuestion,
        mnChoiceList,
        blockService,
        dictionaryService,
        autoCompleteService,
        mnCustomFieldsTable,
        mnCustomFields
    } = require('./blocks/');

    // video call related
    const {videoCallService} = require('./video-call/');

    const {SportDataSetupCtrl, sportDataService, mnSportFile, mnSportFileDetails} = require('./sport-data/');

    const {TechnicalFileSetupCtrl, technicalFileService, mnTechnicalFile} = require('./technical-file/');

    angular
        .module("medinet")

        .component("mnCalendar", calendar)
        .service("calendarService", CalendarService)
        .component("rdvCalendarElement", rdvCalendarElement)
        .component("groupRdvCalendarElement", groupRdvCalendarElement)
        .component("pauseCalendarElement", pauseCalendarElement)
        .component("unavailableCalendarElement", unavailableCalendarElement)

        .service("dcmService", dcmService)

        .filter('fileSize', fileSize)
        .service('uploadService', uploadService)
        .component('mnFiles', mnFiles)
        .component('mnFileItem', mnFileItem)
        .component('mnFileVisualizer', mnFileVisualizer)
        .component('mnPdfViewer', mnPdfViewer)
        .component('mnStlViewer', mnStlViewer)
        .component('mnExcelViewer', mnExcelViewer)
        .component('filesFilter', filesFilter)
        .component('mnImageEditor', mnImageEditor)
        .component('mnImageComparison', mnImageComparison)
        .component('mnContextualFiles', mnContextualFiles)

        .component('mnPatientSummary', mnPatientSummery)
        .component('mnVisitSummary', mnVisitSummary)
        .component('mnConsultationsListSummary', mnConsultationsListSummary)
        .component('mnConsultationSummary', mnConsultationSummary)
        .component('mnPrescriptionsListSummary', mnPrescriptionsListSummary)
        .component('mnCertificatesListSummary', mnCertificatesListSummary)
        .component('mnExamsListSummary', mnExamsListSummary)
        .directive('mnPatientSummaryLink', mnPatientSummaryLink)

        .component('mnImageEdition', mnImageEdition)

        .service('interfacingService', interfacingService)
        .component('interfacingFormBlock', formBlockField)

        /*.component('mnTableMain', table.tableMain)
        .component('columnMenu', table.columnMenu)
        .directive('thRow', table.thRow)
        .directive('thSearch', table.thSearch)
        .service('tableTestService', table.tableService)*/

        .service('editorManagerService', editorManagerService)
        .service('customVariableService', customVariableService)
        .service('editorContextualModelService', editorContextualModelService)
        .component('mnTextEditor', mnTextEditor)
        .component('mnSmsEditor', mnSMSEditor)

        .component("mnAutocomplete", mnAutocomplete)
        .component("mnDictionary", mnDictionary)
        .component("mnFreeDictionary", mnFreeDictionary)
        .component("mnHtmlBlock", mnHtmlBlock)
        .component("mnFormHtmlBlock", mnFormHtmlBlock)
        .component("mnDictTree", mnDictTree)
        .component("mnFileBlock", mnFileBlock)
        .component("mnTree", mnTree)
        .component("mnSurveyBlockResume", surveyResume)
        .component("mnSurveyBlockQuestion", surveyQuestion)
        .component("mnChoiceList", mnChoiceList)
        .component("mnCustomFieldsTable", mnCustomFieldsTable)
        .component("mnCustomFields", mnCustomFields)

        .service("blockService", blockService)
        .service("dictionaryService", dictionaryService)
        .service("autoCompleteService", autoCompleteService)

        //video call related
        .service('videoCallService', videoCallService)

        .controller('SportDataSetupCtrl', SportDataSetupCtrl)
        .service('sportDataService', sportDataService)
        .component("mnSportFile", mnSportFile)
        .component("mnSportFileDetails", mnSportFileDetails)

        .controller('TechnicalFileSetupCtrl', TechnicalFileSetupCtrl)
        .service('technicalFileService', technicalFileService)
        .component("mnTechnicalFile", mnTechnicalFile)
})();
