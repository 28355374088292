/**
 * Created by amine on 08/09/2017. edited by amine 26/07/2021
 */
(function () {

    'use strict';

    class FillCustomVarFormDialogController {
        constructor($mdDialog, moment, system) {
            this.dialog = $mdDialog;
            this.moment = moment;
            this.dateFormat = system.date_format.js;
            this.timeFormat = system.time_format.js;
        }

        static get $inject() {
            return ["$mdDialog", "moment", "system"];
        }

        $onInit() {
            this.metas = this.metas || [];
            this.data = _.reduce(this.metas, (acc, item) => {
                switch (item.type) {
                    case "date":
                        const value = _.get(item, "default.value");
                        const useNowDate = _.get(item, "default.now", false);

                        acc[item.slug] = useNowDate ? this.moment() : value;

                        break;
                    case "datetime":
                        const dateValue = _.get(item, "default.date");
                        const timeValue = _.get(item, "default.time");
                        const useNowDatetime = _.get(item, "default.now", false);

                        acc[item.slug] = useNowDatetime ? {
                            date: this.moment().format(this.dateFormat),
                            time: this.moment().format(this.timeFormat)
                        } : {
                            date: dateValue,
                            time: timeValue
                        };

                        break;
                    default:
                        acc[item.slug] = item.default;
                }
                return acc;
            }, {});
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.dialog.hide(this.data);
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: FillCustomVarFormDialogController,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        template: require("./fill-custom-var.tpl.html"),
    };

})();