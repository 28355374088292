/**
 * Created by amine on 21/01/2020.
 */
(function () {

    'use strict';

    class PregnancyEventFormDialogController {
        constructor($mdDialog, pregnancyService, $scope) {
            this.dialog = $mdDialog;
            this.pregnancyService = pregnancyService;
            this.scope = $scope;
            this.init = false;
        }

        static get $inject() {
            return ["$mdDialog", "pregnancyService", "$scope"];
        }

        $onInit() {
            this.config = _.assign({
                is_event: true,
                time_interval: {
                    start: 0,
                    end: null,
                    week_count: 0,
                    is_infinite: false,
                    is_amenorrhea_based: true,
                }
            }, this.config);
            this.init = true;
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.pregnancyService
                .saveConfigurationEvent(this.config)
                .then(data => this.dialog.hide(data));
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: PregnancyEventFormDialogController,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("./pregnancy-event-form.tpl.html"),
    };


})();
