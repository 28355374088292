/**
 * Created by Amine on 26/08/2016. [edited by Amine on 17/10/2019]
 */
(function () {

    'use strict';

    const {AsyncSubject, isObservable} = require("rxjs");

    class TextEditorCtrl {
        constructor($element, editorManagerService) {
            this.$element = $element;
            this.editorManagerService = editorManagerService;

            this.tinymce = require('tinymce/tinymce');

            this.ngModelController = this.ngModelController || null;
            this.label = this.label || null;
            this.labelColor = this.labelColor || null;
            this.minimal = this.minimal || null;
            this.configKey = this.configKey || null;
            this.config = {};
            this.customMenus = [];
        }

        static get $inject() {
            return ["$element", "editorManagerService"];
        }

        $onInit() {
            if (_.isNil(this.options)) this.options = {};
            if (_.isNil(this.name)) this.name = _.uniqueId("mce__");

            $("textarea", this.$element).attr("id", this.name);

            this.readonly = !!this.readonly || false;
            this.config.selector = `textarea#${this.name}`;
            this.config.onSetup = _.noop;
            this.options.minimal = !!this.minimal || !!this.options.minimal;

            if (this.label) this.$element.addClass("with-label");
            if (this.options.minimal) this.$element.addClass("minimal");

            if (isObservable(this.customMenus)) {
                this.config.customMenus = this.customMenus;
            } else if (this.customMenus && this.customMenus.length > 0) {
                this.config.customMenus = new AsyncSubject(this.customMenus)
            } else {
                this.config.customMenus = false;
            }

            this.editorManagerService
                .createEditor(this.name, this.configKey, this.options, this.config)
                .then(editor => this.editorInitialized(editor));
        }

        $onDestroy() {
            this.editorManagerService.destroyTinymce(this.name);
        }

        $onChanges(changes) {
            let editor = this.editorManagerService.getTinymce(this.name);

            if (editor && changes.readonly) {
                this.editorReadonly(editor, changes.readonly.currentValue)
            }
        }

        editorReadonly(editor, readonly) {
            if (readonly) {
                editor.setMode('readonly');
            } else {
                editor.setMode('design');
            }
        }

        editorInitialized(editor) {
            editor.show();

            if (!_.isNil(this.ngModelController.$modelValue) && !_.isEmpty(this.ngModelController.$modelValue)) {
                editor.setContent(this.ngModelController.$modelValue);
            }

            this.ngModelBehaviour(editor);
            this.editorReadonly(editor, this.readonly);
        }

        ngModelBehaviour(editor) {
            editor
                .on("ExecCommand change keyup NodeChange ObjectResized", () => this.editorEventTriggered(editor))
                .on("blur", () => this.ngModelController.$setTouched())
                .on("init", () => {
                    this.ngModelController.$setPristine();
                    this.ngModelController.$setUntouched();
                });

            this.ngModelController.$render = () => {
                editor.setContent(this.ngModelController.$viewValue || "");
            };
        }

        editorEventTriggered(editor) {
            let content = editor.getContent();

            if (content.length !== 0 || !_.isEmpty(this.ngModelController.$modelValue)) {
                this.ngModelController.$setViewValue(content);
                this.ngModelController.$commitViewValue();
            }
        }
    }

    tpl.$inject = [];

    function tpl() {
        return `
            <label ng-bind="vm.label" ng-class="{'custom-color': vm.labelColor}" ng-style="{color: vm.labelColor}" ng-if="vm.label"></label>
            <textarea style="display: none;"></textarea>
        `
    }

    module.exports = {
        controller: TextEditorCtrl,
        controllerAs: "vm",
        bindings: {
            name: '@name',
            readonly: "<?",
            configKey: '@key',
            minimal: '<minimal',
            label: '@?withLabel',
            labelColor: "@?",
            options: '<',
            customMenus: '<'
        },
        require: {ngModelController: "ngModel"},
        template: tpl
    };
})
();