/**
 * Created by amine on 11/02/2019.
 */
(function () {
    'use strict';

    class FileBlockController {
        constructor($q, $scope, medicalFileBlockUID) {
            this.$q = $q;
            this.$scope = $scope;

            this.key = null;
            this.patient = null;
            this.label = "";
            this.readonly = false;
            this.postSave = _.noop;
            this.postSaveSubject = null;
            this.uploadContext = null;
            this.consultationId = null;
            this.medicalFileBlockUID = medicalFileBlockUID;
        }

        static get $inject() {
            return ["$q", "$scope", "medicalFileBlockUID"];
        }

        $onInit() {
            this.consultationId = !!this.postSaveSubject ? this.postSaveSubject.getValue() : null;
            this.setContext();
        }

        preSave() {
            const deferred = this.$q.defer()
            let subscription = null;
            if (!!this.consultationId) {
                subscription = this.postSaveSubject.subscribe(data => {
                    if (!data) return false;

                    this.consultationId = data;
                });
            }
            this.setContext()
                .then(() => {
                    if (!!subscription) subscription.unsubscribe();

                    this.postSave();
                    deferred.resolve(this.consultationId);
                });

            return deferred.promise;
        }

        setContext() {
            const deferred = this.$q.defer();

            this.$scope.$applyAsync(() => {
                if (this.key === this.medicalFileBlockUID) {
                    this.uploadContext = {block: this.key, patient: this.patient}
                } else {
                    this.uploadContext = {block: this.key, consultation: this.consultationId}
                }
                setTimeout(() => deferred.resolve(null));
            });

            return deferred.promise;
        }
    }

    module.exports = {
        bindings: {
            key: "@mnBlockKey",
            patient: "<",
            label: "@?",
            readonly: "&?",
            postSave: '&?',
            postSaveSubject: '<?',
            labelColor: "@?"
        },
        controllerAs: "vm",
        controller: FileBlockController,
        template: require('stand-alone/blocks/views/file-block.tpl.html')
    };

})();