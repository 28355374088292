(function () {

    'use strict';

    const CALENDAR_DURATION_DIALOG = require('frontdesk/dialogs/calendar-duration-dialog');
    const APPOINTMENT_AVAILABILITY_DIALOG = require('frontdesk/dialogs/appointement-availability-dialog');
    const {UPDATE, APPOINTMENT, WAITING_LIST, BACKGROUND, DELETE} = require('stand-alone/calendar/utils/consts');

    ServiceOrderSchedulingDialogCtrl.$inject = ["$mdDialog", "system", "mnWebSocket", "patientService", "ServiceOrderService", "appointmentFormService", "$state", "configService", "$q"];

    function ServiceOrderSchedulingDialogCtrl($mdDialog, system, mnWebSocket, patientService, ServiceOrderService, appointmentFormService, $state, configService, $q) {
        let vm = this;

        const timeFormat = system['time_format'].js;
        const dateFormat = system['date_format'].js;

        vm.$onInit = init;

        vm.submit = submit;

        vm.remove = remove;
        vm.cancel = $mdDialog.cancel;

        vm.changeInterval = changeInterval;
        vm.showAvailabilities = showAvailabilities;


        vm.checkToday = checkToday;

        function init() {
            vm.toPractice = false;
            vm.maxDate = moment().toDate();
            vm.model = null;
            if (!_.isNil(vm.event)) handleEvent();
        }

        function submit() {
            let deferred = $q.defer();
            vm.promise = ServiceOrderService.handleTicket(vm.model)
                .then(doneCallbacks, deferred.reject);

            return deferred.promise;

            function doneCallbacks(data) {
                vm.model.id = data.id;
                $mdDialog.hide(vm.model);
                deferred.resolve(data);
            }
        }


        function remove() {
        }

        function handleTimeRange(timeRange, timeout) {
            const assignObject = {
                date: timeRange.start.format(dateFormat),
                start_time: timeRange.start.format(timeFormat),
                end_time: timeRange.end.format(timeFormat)
            };

            vm.pause = _.assign(vm.pause, assignObject);
            vm.appointment = _.assign(vm.appointment, assignObject);

            let agenda = timeRange.agenda;
            let physician = timeRange.physician;

            checkToday();

            if (timeout) setTimeout(setResource, 50);
            else setResource();

            function setResource() {
                if (agenda) vm.appointment.agenda = agenda;
                if (physician) vm.appointment.physician = vm.pause.physician = physician;
            }
        }

        function handleEvent() {
            vm.model = vm.event;
            if(!_.isNil(vm.model.patient)){
                patientService.getMinimalPatient(vm.model.patient.id, true).then(data=>{
                    vm.model.patient=data;
                });
            }

        }

        function changeInterval() {
            vm.model = appointmentFormService.changeInterval(vm.model);
        }

        function showAvailabilities(ev) {
            appointmentFormService
                .showAvailabilities(vm.appointment, ev)
                .then(data => vm.appointment = data, _.noop);
        }

        function checkToday() {
            vm.is_today = appointmentFormService.checkToday(vm.appointment);
        }
    }

    module.exports = {
        locals: {},
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        controller: ServiceOrderSchedulingDialogCtrl,
        template: require("./service-order-scheduling-dialog.tpl.html"),
    };

})();
