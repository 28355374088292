/**
 * Created by amine on 28/07/2018.
 */

(function () {
    "use strict";
    const BasePlugin = require("./base");
    const EDITOR_CONTEXTUAL_TEMPLATE_DIALOG = require("../dialogs/editor-contextual-templates/editor-contextual-templates");


    class ContextualModelManager extends BasePlugin {
        constructor(editorInstance) {
            super(editorInstance, "contextual_template_manager");

            this.editorContextualModelService = this.editorInstance.editorContextualModelService
            this.dialog = this.editorInstance.dialog;
            this.translate = this.editorInstance.translate;

            this.context = null;
        }

        initPlugin() {
            this.addIcon("receipt-text")
            this.addIcon("receipt-text-plus")
            this.addIcon("receipt-text-outline")

            this.addMenuButton("templates", {
                tooltip: this.translate.instant("editor_custom_models"),
                icon: "receipt-text",
                fetch: callback => this.fetchModels(callback)
            });
        }

        fetchModels(callback) {
            this.editorContextualModelService.getModels()
                .then(templates => {
                    const items = [
                        {
                            type: 'menuitem',
                            text: this.translate.instant("editor_custom_models_list"),
                            icon: "receipt-text",
                            onAction: event => this.showTemplates(event)
                        },
                        {
                            type: 'menuitem',
                            text: this.translate.instant("editor_add_custom_models"),
                            icon: "receipt-text-plus",
                            onAction: event => this.saveSelectionAsModel(event)
                        },
                        {
                            type: 'separator'
                        }
                    ];
                    templates.map(template => {
                        const contextKey = _.get(template, "context.key");
                        const contextCondition = _.get(template, "context.key") === _.get(this.context, "key") || !contextKey;
                        if (template.is_bookmarked && contextCondition) {
                            items.push({
                                type: 'menuitem',
                                text: template.title,
                                icon: "receipt-text-outline",
                                onAction: () => this.insertModel(template)
                            })
                        }
                    })
                    callback(items);
                });
        }

        insertModel(template) {
            this.tinymceEditor.execCommand("mceInsertContent", false, template.content);
        }

        showTemplates(event) {
            this.dialog
                .show(
                    _.assign(
                        {},
                        EDITOR_CONTEXTUAL_TEMPLATE_DIALOG,
                        {
                            locals: {
                                tinymceEditor: this.tinymceEditor,
                                context: this.context
                            },
                            targetEvent: event
                        }
                    )
                ).then(_.noop, _.noop);
        }

        saveSelectionAsModel(event) {
            const content = this.tinymceEditor.selection.getContent();
            if (!content) return false;

            let prompt = this.dialog.mnPromptDialog()
                .title('exam_model_title')
                .event('shared.EditorContextualModel.title_validation')
                .query({id: null})
                .placeholder('title')
                .targetEvent(event);

            this.dialog.show(prompt)
                .then(title => {
                    this.editorContextualModelService.saveModel(
                        {
                            title,
                            content,
                            context: this.context
                        }
                    );
                }, _.noop);
        }

        setContext(context) {
            this.context = context;
        }
    }

    module.exports = ContextualModelManager;
})()
