/**
 * Created by amine on 26/07/2017.
 */
(function () {
    'use strict';

    module.exports = {
        editorManagerService: require('./services/editorManagerService'),
        customVariableService: require('./services/customVariableService'),
        editorContextualModelService: require('./services/editorContextualModelService'),
        mnTextEditor: require('./components/text-editor/text-editor'),
        mnSMSEditor: require('./components/sms-editor/sms-editor')
    };
})();
