/**
 * Created by amine on 08/08/2018.
 */
(function () {
    'use strict';

    class EditorContextualModelService {
        constructor($q, mnWebSocket, $http, $mdDialog) {
            this.$q = $q;
            this.mnWebSocket = mnWebSocket;
            this.$http = $http;
            this.$mdDialog = $mdDialog;
        }

        static get $inject() {
            return ["$q", "mnWebSocket", "$http", "$mdDialog"];
        }

        saveModel(item, httpMethod = 'post') {
            const url = `/api/editor-model/${!!item.id ? `${item.id}/` : ''}`;
            let deferred = this.$q.defer();

            this.$http[httpMethod](url, item).then((response) => deferred.resolve(response.data), deferred.reject)

            return deferred.promise;
        }

        deleteModel(item) {
            const url = `/api/editor-model/${_.has(item, "id") ? item.id : item}/`;
            let deferred = this.$q.defer();

            this.$http.delete(url).then(
                response => deferred.resolve(response.data),
                response => deferred.resolve(response.data)
            );

            return deferred.promise;
        }

        getModels() {
            const url = "/api/editor-model/";
            let deferred = this.$q.defer();

            this.$http.get(url).then((response) => deferred.resolve(response.data), deferred.reject)

            return deferred.promise;
        }
    }

    module.exports = EditorContextualModelService;

})();