/**
 * Created by amine on 22/03/2017.
 */
(function () {
    'use strict';

    const angular = require('angular');

    const mnHumanSkeleton = require('./components/human-skeleton');
    const TraumatoContainerCtrl = require('./controllers/TraumatoContainerCtrl');

    const appointmentFormButton = require('./components/appointment-form-button/appointment-form-button')

    const pregnancyBlock = require('./components/pregnancy-block/pregnancy-block');
    const gynecologyInfo = require('./components/gynecology-info');
    const pregnancyTerm = require('./components/pregnancy-term/pregnancy-term');
    const pregnancyService = require('./services/pregnancyService');

    const ophthalmicService = require('./services/ophthalmicService');
    const ophthalmicPrescription = require('./components/ophthalmic-prescription');

    const vaccinationService = require('./services/vaccinationService');
    const vaccinationCalendar = require('./components/vaccination-calendar-block/vaccination-calendar-block');
    const vaccinationAgeInterval = require('./components/vaccination-age-interval/vaccination-age-interval');

    const mnHurExam = require('./components/hur-exam');

    const observationService = require('./services/observationService');
    const ptBlock = require('./components/prothrombin-time-block');
    const ptService = require('./services/ptService');

    const hurService = require('./services/hurService');

    const diagnosticsBlock = require('./components/diagnostics-block/diagnostics-block');
    const classificationTree = require('./components/classification-tree/classification-tree');
    const icdField = require('./components/icd-field/icd-field');
    const osiicsField = require('./components/osiics-field/osiics-field');
    const customClassificationField = require('./components/custom-classification-field/custom-classification-field');
    const classificationsService = require('./services/classificationsService');
    const pathologyService = require('./services/pathologyService');

    const growthCharts = require('./directives/growth-charts');
    const GXPService = require('./services/gxpService');
    const mnServiceOrderSchedulingButton = require('./components/service-order-scheduling-button/service-order-scheduling-button');

    angular
        .module("medinet")

        .controller('TraumatoContainerCtrl', TraumatoContainerCtrl)
        .component('mnHumanSkeleton', mnHumanSkeleton)

        .component('mnAppointmentFormButton', appointmentFormButton)

        .component('mnPregnancyBlock', pregnancyBlock)
        .component('mnGynecologyInfo', gynecologyInfo)
        .component('mnPregnancyTerm', pregnancyTerm)
        .service('pregnancyService', pregnancyService)

        .service('ophthalmicService', ophthalmicService)
        .component('mnOphthalmicPrescription', ophthalmicPrescription)

        .service('vaccinationService', vaccinationService)
        .component('mnAgeInterval', vaccinationAgeInterval)
        .component('mnVaccinationCalendar', vaccinationCalendar)

        .service('observationService', observationService)

        .component('mnPtBlock', ptBlock)
        .service('ptService', ptService)

        .component('mnHurExam', mnHurExam)
        .service('hurService', hurService)

        .component('mnDiagnosticsBlock', diagnosticsBlock)
        .component('mnClassificationTree', classificationTree)
        .component('mnIcdField', icdField)
        .component('mnOsiicsField', osiicsField)
        .component('mnCustomClassificationField', customClassificationField)
        .service('classificationsService', classificationsService)
        .service('pathologyService', pathologyService)

        .directive('growthCharts', growthCharts)
        .service('gxpService', GXPService)
        .component('mnServiceOrderSchedulingButton',mnServiceOrderSchedulingButton);
})();