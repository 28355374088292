/**
 * Created by Amine on 01/11/2022.
 */
(function () {
    'use strict';

    class DictionaryHistoryDialogCtrl {
        constructor($mdDialog) {
            this.dialog = $mdDialog;
        }

        static get $inject() {
            return ["$mdDialog"];
        }

        $onInit() {
            this.items = this.handleItems(this.items) || [];
            this.noValues = _.every(this.items, item => {
                return !item.value;
            });
        }

        handleItems(items) {
            return items.map(item => {
                return _.assign({}, item, {
                    freeDictionary: !_.isArray(item.value)
                })
            });
        }

        transformChip(chip) {
            if (_.isObject(chip)) return chip;
            else return {value: chip};
        }

        useItem(item, replace = true) {
            this.dialog.hide({
                value: item.freeDictionary ? item.value.value : item.value,
                replace
            });
        }

        cancel() {
            this.dialog.cancel();
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: DictionaryHistoryDialogCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("./dictionary-history-dialog.tpl.html"),
    };

})();