/**
 * Edited 20/07/2021
 */

(function () {
        "use strict";

        const BasePlugin = require("./base");
        const SpeechRecognition = require('../utils/speech-recognition');

        class SpeechRecognitionCapability extends BasePlugin {

            constructor(editorInstance) {
                super(editorInstance, "speech_recognition");

                this.translate = editorInstance.translate;

                this.helper = new SpeechRecognition();
                this.micElement = null;
            }

            initPlugin() {
                this.addIcon("microphone");

                if (this.helper.recognition) {
                    this.addToggleButton("editor_speech", {
                        tooltip: this.translate.instant("editor_speech"),
                        onAction: event => this.listen(event),
                        icon: "microphone"
                    });
                }
            }

            afterEditorInit() {
                this.micElement = $("<div class='editor-speech-div md-whiteframe-z1 layout-row layout-align-center-center' >")
                    .append('<span class="mdi mdi-microphone">');

                $('.tox-edit-area', this.tinymceEditor.getContainer())
                    .append(this.micElement);

                this.tinymceEditor.on('FullscreenStateChanged', (e) => {
                    if (!e.state && !this.helper.isStopped && this.toggleEvent) {
                        this.#stop();
                        this.micElement.toggleClass("enabled", false);
                    }
                });
            }

            listen(event) {
                this.toggleEvent = event;
                const isActive = !this.toggleEvent.isActive();
                this.toggleEvent.setActive(isActive)

                if (isActive) {
                    this.#start();
                } else {
                    this.#stop();
                }

                this.micElement.toggleClass("enabled", isActive);
            }

            get #fullscreenPlugin() {
                return this.tinymceEditor.plugins.fullscreen;
            }

            #start() {
                this.helper.start();
                this.helper.subject.subscribe(results => this.#updateContent(results));
                if (!this.#fullscreenPlugin.isFullscreen()) this.tinymceEditor.execCommand('mceFullScreen');
            }

            #stop() {
                this.helper.stop();
                if (this.#fullscreenPlugin.isFullscreen()) this.tinymceEditor.execCommand('mceFullScreen');
                if (this.toggleEvent.isActive()) this.toggleEvent.setActive(false);
            }

            #updateContent(results) {
                const $body = $(this.tinymceEditor.getBody());
                const final = _.get(results, "results.0.isFinal");
                const transcript = _.get(results, "results.0.0.transcript") + (final ? " " : "");
                const span = $("<span / >").append(transcript).addClass(final ? "valid-speech" : "ne speech");

                if ($(".speech", $body).length > 0) $(".speech", $body).replaceWith(span);
                else this.tinymceEditor.execCommand("mceInsertContent", false, span.prop('outerHTML'));

                this.tinymceEditor.fire("keyup");
            }

        }

        module.exports = SpeechRecognitionCapability;
    }

)();