(function () {

    'use strict';

    const VISUALIZE_FILE_DIALOG = require('stand-alone/file-manager/dialogs/visualize-file-dialog');

    class FirePacsStudiesDialogCtrl {
        constructor($mdDialog, dcmService) {
            this.dialog = $mdDialog;
            this.dcmService = dcmService;

            this.selectStudy = (study) => this.selectStudyFunc(study);
        }

        static get $inject() {
            return ["$mdDialog", "dcmService"];
        }

        $onInit() {
            this.preSave = this.preSave ? this.preSave : null;
            this.resolve = this.resolve ? this.resolve : false;

            this.actions = {
                multiple: [],
                single: [
                    {
                        action: 'get',
                        resource: 'dcm',
                        icon: 'mdi-file',
                        behavior: 'disable',
                        label: 'visualize_joined_file',
                        method: (item, $event) => this.visualize(item, $event)
                    }
                ]
            };
        }

        cancel() {
            this.dialog.cancel();
        }

        visualize(item, $event) {
            $event.stopPropagation();

            this.promise = this.dcmService.getFirePacsPreviews(item)
                .then(previews => {
                    if (previews.files.length === 0) return;

                    const dialog = _.assign({}, VISUALIZE_FILE_DIALOG, {
                        targetEvent: $event,
                        locals: previews
                    });

                    this.dialog.show(dialog, _.noop);
                })
        }

        selectStudyFunc(study) {
            if (this.resolve) this.dialog.hide(study);
            else if (!_.isNil(this.preSave)) this.promise = this.preSave().then(() => this.startSave(study));
            else this.startSave(study);
        }

        startSave(study) {
            setTimeout(() => {
                this.promise = this.dcmService.handleFirePacsStudy(this.patient, this.context, study)
                    .then(this.dialog.hide);
            }, 200);
        }

        clearQuery() {
            this.query = {};
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: FirePacsStudiesDialogCtrl,
        template: require("./fire-pacs-studies-dialog.tpl.html"),
    };

})();
