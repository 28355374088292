/**
 * Created by amine on 08/09/2017.
 */
(function () {

    'use strict';

    const generateId = require('shared/utils/unique-id');
    const INPUT_TYPES = [
        {name: "text"},
        {name: "longtext"},
        {name: "date"},
        {name: "datetime"},
        {name: "integer"},
        {name: "float"},
        {name: "list", params: true, data: [null]},
        {name: "list_longtext", params: true, data: [{title: null, content: null}]}
    ];

    class CustomVarFormCtrl {
        constructor($mdDialog, customVariableService, $scope, authService) {
            this.dialog = $mdDialog;
            this.service = customVariableService;
            this.scope = $scope;

            this.isRoot = authService.isRootProfile();

            this.inputTypes = INPUT_TYPES;
            this.params = false;
            this.reset = null;
            this.promise = null;
            this.actions = {
                single: [
                    {
                        icon: 'mdi-pencil',
                        label: 'edition',
                        resource: 'general-config',
                        action: 'update',
                        behavior: 'disable',
                        method: (meta, event) => this.getVariable(true, meta, event)
                    },
                    {
                        icon: 'mdi-tag-check',
                        label: 'use',
                        resource: 'general-config',
                        action: 'get',
                        behavior: 'disable',
                        method: (meta, event) => this.getVariable(false, meta, event)
                    }
                ],
                multiple: []
            };
        }

        static get $inject() {
            return ["$mdDialog", "customVariableService", "$scope", "authService"];
        }

        $onInit() {
            this.meta = _.assign({id: null, type: "text"}, this.meta || {});
            this.type = _.find(INPUT_TYPES, {name: this.meta.type});

            this.params = _.get(this.type, "params", false);
            this.meta.data = _.get(this.meta, "data", null);

            const $unwatch = this.scope.$watch("vm.reset", (newValue) => {
                if (newValue) {
                    this.reset({search: {}}, false);
                    $unwatch();
                }
            });
        }

        cancel() {
            this.dialog.cancel();
        }

        labelChanged(notUnique = false) {
            if (this.meta.id) return false;
            this.meta.slug = _.kebabCase(this.meta.label);
            if (notUnique) this.meta.slug += `-${generateId(4, "", false)}`
        }

        typeChanged() {
            const type = _.find(INPUT_TYPES, {name: this.meta.type});

            this.params = _.get(type, "params", false);
            this.meta.data = _.get(type, "data", null);
        }

        getVariable(edit, meta, event) {
            event.stopPropagation();

            this.meta = meta;
            if (!edit) this.submit();
            else this.selectedTab = 0;
        }

        prepareMeta() {
            this.meta.key = `custom::${this.meta.slug}`;
        }

        submit() {
            this.prepareMeta();
            this.dialog.hide(this.meta);
        }

        saveVariable(validate) {
            this.prepareMeta();
            this.service.saveVariable(this.meta)
                .then(() => {
                    if (validate) this.submit();
                    else {
                        this.meta = _.assign({
                            id: null,
                            type: "text"
                        }, {});

                        this.scope.customVarForm.$setPristine();
                        this.selectedTab = 1;
                        if (_.isFunction(this.reset)) this.reset();
                    }
                });

        }

        addNewToList(obj) {
            this.meta.data.push(obj);
        }

        removeFromList($index) {
            this.meta.data.splice($index, 1);
        }

        resetForm() {
            this.form = false;
            this.meta = _.assign({}, {id: null, type: "text"});
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: CustomVarFormCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("./custom-var.tpl.html"),
    };
})();