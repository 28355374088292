/**
 * Created by amine on 27/06/2018.edited 03/10/2022.
 */
(function () {

    'use strict';

    class VaccinationLineDialogCtrl {
        constructor($mdDialog, vaccinationService) {
            this.dialog = $mdDialog;
            this.vaccinationService = vaccinationService;

            this.promise = null;
            this.vaccines = [];
            this.selectedVaccines = null;
            this.line = null;
            this.vaccination = null;
        }

        static get $inject() {
            return ["$mdDialog", "vaccinationService"];
        }

        $onInit() {
            this.promise = this.vaccinationService.getVaccines()
                .then(data => this.vaccines = data);
        }

        addVaccine() {
            this.line.vaccines = _.chain(this.line.vaccines)
                .concat(this.selectedVaccines)
                .uniqBy('id')
                .filter(function (item) {
                    return !_.isNil(item)
                })
                .value();

            this.selectedVaccines = null;
        }

        removeVaccine(item) {
            _.remove(this.line.vaccines, _.pick(item, "id"));
        }

        submit() {
            this.addVaccine();
            this.vaccinationService.editCalendarLine({
                line: this.line,
                vaccination: this.vaccination
            }).then(data => this.dialog.hide(data), _.noop);

        }

        cancel() {
            this.dialog.cancel();
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: VaccinationLineDialogCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("./vaccination-line-form.tpl.html"),
    };

})();