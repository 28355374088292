/**
 * Created by Hp on 25/09/2017.
 */
/**
 * Created by BETALOS on 14/06/2016.
 */
(function () {

    'use strict';

    module.exports = conventionService;

    conventionService.$inject = ["$q", "$http", "mnWebSocket","system"];

    function conventionService($q, $http, mnWebSocket,system) {
        var self = this;
        var dateFormat = system['date_format'].js;
        self.saveConvention = saveConvention;
        self.getConventions = getConventions;
        self.getConventionsList = getConventionsList;
        self.getConvention = getConvention;
        self.handleLineConventionPrice = handleLineConventionPrice;

        function saveConvention(convention) {
            var deferred = $q.defer();
            var url = "/api/convention/";
            if (!_.isNil(convention.id)) url += convention.id + "/";

            $http.post(url, convention).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }


        function getConventions(query) {
            return mnWebSocket.call("shared.insurance.Convention.search_convention", query);
        }

        function getConventionsList() {
            const deferred = $q.defer();
            const url = "/api/convention/";

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getConvention(pk) {
            const deferred = $q.defer();

            $http.get(`/api/convention/${pk}/`)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function handleLineConventionPrice(convention, patient, line) {
            //todo convention.tp_max_amount must be considered in docs total amount
            if (convention && convention.is_activated && moment().isBetween(moment(convention.start_date, dateFormat),
                moment(convention.end_date, dateFormat))) {

                let defaultAcc = {tp: convention.tp, tm: convention.tm, tp_max_amount: convention.tp_max_amount};
                if (convention.parent) {
                    defaultAcc = handleExceptionsPrice(convention.parent, patient, line, {
                        tp: convention.parent.tp,
                        tm: convention.parent.tm,
                        tp_max_amount: convention.parent.tp_max_amount
                    });
                }
                if (!_.isEmpty(convention.exceptions)) {
                    return handleExceptionsPrice(convention, patient, line, defaultAcc);
                }
                return defaultAcc;
            }
            //todo and tp_max_amount must be considered in line tp_amount
            return {tm: 100, tp: 0, tp_max_amount: null};
        }

        function handleExceptionsPrice(convention, patient, line, defaultAcc) {
            return _.reduce(convention.exceptions, (acc, e) => {
                if (evaluateRules(e.rules, patient, line)) {
                    acc.tp = e.tp;
                    acc.tm = e.tm;
                }
                return acc;
            }, _.cloneDeep(defaultAcc));
        }

        function evaluateRules(rules, patient, line) {
            return eval(_.reduce(rules, (acc, r) => {
                return `${acc} (${r.inner_operator.js
                    .replace('v1', getRuleKeyValue(r.key.name,
                        r.rule_category, patient, line))
                    .replace('v2', `'${r.value}'`)}) ${_.get(r.outer_operator, 'js', '')}`;
            }, ''));
        }

        function getRuleKeyValue(key, category, patient, line) {
            switch (category) {
                case 'patient':
                    return `'${_.get(patient, key)}'`;
                case 'procedures':
                case 'general_stock_config':
                    return `'${_.get(line, key)}'`;
                default:
                    return false;
            }
        }
    }

})();
