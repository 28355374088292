import {Calendar} from '@fullcalendar/core';
import {ThirdPartyDraggable} from '@fullcalendar/interaction';

import {PRESETS} from "../utils/presets";
import MenuCtrl from 'shared/utils/menu-ctrl';

const {UPDATE, APPOINTMENT, EVENT_SOURCE_ID} = require('stand-alone/calendar/utils/consts');

const CALENDAR_DATE_NAVIGATION_ELEMENT = `
    <div class="calendar-navigation-panel md-whiteframe-z2">
        <md-calendar ng-model="vm.navigationDate" ng-change="vm.navigationChange()"></md-calendar>
    </div>
`;

class CalendarCtrl {
    constructor(
        $element, $scope, $compile, $mdPanel, configService, frontDeskService, groupAppointmentService, physicianService, mnWebSocket, dragulaService,
        $translate, $timeout, system, calendarService, authService, moment, $q
    ) {
        this.scope = $scope;
        this.element = $element;
        this.compile = $compile;
        this.timeout = $timeout;
        this.moment = moment;
        this.translate = $translate;
        this.mnWebSocket = mnWebSocket;
        this.configService = configService;
        this.dragulaService = dragulaService;
        this.frontDeskService = frontDeskService;
        this.groupAppointmentService = groupAppointmentService;
        this.physicianService = physicianService;
        this.calendarService = calendarService;
        this.authService = authService;
        this.q = $q;

        this.locale = system.lang;
        this.waitingListItems = [];
        this.filter = this.filter || null;
        this.callback = this.callback || _.noop;
        this.groupCallback = this.groupCallback || _.noop;
        this.timeFormat = system['time_format'].js;
        this.dateFormat = system['date_format'].js;

        this.navigationDate = this.moment().toDate();
        this.menuCtrl = new MenuCtrl($mdPanel, $scope, $compile);

        this.canCreate = this.authService.aclHandler({resource: 'appointment', action: 'create'});
        this.canUpdate = this.authService.aclHandler({resource: 'appointment', action: 'update'});
    }

    static get $inject() {
        return [
            "$element", "$scope", "$compile", "$mdPanel", "configService", "frontDeskService", "groupAppointmentService",
            "physicianService", "mnWebSocket", "dragulaService", "$translate", "$timeout", "system", "calendarService",
            "authService", "moment", "$q"
        ];
    }

    $onInit() {
        this.$waitingNav = $("md-sidenav[md-component-id=waiting-list-nav]", this.element);
        this.subscription = this.frontDeskService.resourceSubject.subscribe(() => this.reloadResource());

        this.changesSubscription = this.calendarService.eventsChanges(this)
            .subscribe(waitingListItem => {
                const items = _.pushOrUpdate(this.waitingListItems, waitingListItem);
                this.handleWaitingList(items);
            });

        // drag and drop related
        const draggableContainer = $('.mn-waiting-list-nav > md-content', this.element).get(0);
        this.thirdPartyDraggable = new ThirdPartyDraggable(draggableContainer, {
            itemSelector: '.fc-event',
            mirrorSelector: '.gu-mirror',
            eventData: eventEl => $(eventEl).data('event')
        });

        this.dragulaService.options(this.scope, "waiting-list-bag", {
            copy: true,
            revertOnSpill: false, moves: () => this.canUpdate,
            invalid: el => $(el).hasClass("empty-list")
        });

        this.promise = this.configService.getByHttp("calendar_config")
            .then(data => this.loadCalendar(data));
    }

    $onChanges(changes) {
        const filter = _.get(changes, "filter.currentValue", false);

        if (!filter) return;
        if (this.calendar) this.calendar.refetchEvents();
        if ("physician" in filter || "agenda" in filter) this.reloadResource();

        this.calendarService.getWaitingList(filter).then((items) => this.handleWaitingList(items));
    }

    $onDestroy() {
        if (this.calendar) this.calendar.destroy();
        if (this.subscription) this.subscription.unsubscribe();
        if (this.thirdPartyDraggable) this.thirdPartyDraggable.destroy();
        if (this.changesSubscription) this.changesSubscription.unsubscribe();
    }

    loadCalendar(config) {
        const $calendarEl = $('.calendar', this.element);
        const handledConfig = _.assign({}, PRESETS, this.getConfig(config));

        this.slotDuration = this.moment.duration(config['slot_duration']).asMinutes();
        this.closingDays = _.assign({}, config['closing_days'], {
            id: "cd",
            overlap: false,
            editable: false,
            unavailable: true,
            endTime: "23:59:59",
            startTime: "00:00:00",
            display: "background",
        });

        this.closingDays.daysOfWeek = _.has(this.closingDays, 'dow') ? this.closingDays['dow'] : [];

        this.calendar = new Calendar($calendarEl.get(0), handledConfig);
        this.calendar.render();

        setTimeout(() => this.calendar.updateSize(), 150);
    }

    getConfig(config) {
        return {
            slotMinTime: config['min_time'],
            slotMaxTime: config['max_time'],
            initialView: config['default_view'],
            slotDuration: config['slot_duration'],
            firstDay: _.get(config, 'first_day', 1),
            //scrollTime: this.moment().format(this.timeFormat),

            hiddenDays: config['hide_closing_days'] ? _.chain(config).get('closing_days.dow').map(i => parseInt(i)).value() : [],

            locale: this.locale,
            slotLabelFormat: this.timeFormat,
            timeZone: config['time_zone'] || 'Africa/Casablanca',

            drop: (info) => this.eventDrop(info),
            eventReceive: info => this.eventResizeOrMove(info, true),

            resourceOrder: 'order',
            resourceLabelDidMount: instance => this.resourceRender(instance),
            resources: (info, callback, error) => this.getResources(callback, error, config['use_abbr']),

            eventConstraint: {
                start: this.moment().format('YYYY-MM-DD'),
                end: '2100-01-01' // hard coded goodness unfortunately
            },
            customButtons: {
                waitingListToggle: {
                    icon: 'mdi mdi-menu',
                    click: () => this.toggleWaitingList()
                },
                calendarButton: {
                    icon: 'mdi mdi-calendar-text',
                    click: ev => this.calendarNavigation(ev)
                },
                addWaitingList: {
                    icon: `mdi mdi-plus ${this.canCreate ? '' : 'disabled'}`,
                    click: this.canCreate ? ev => this.addAppointmentToWL(ev) : false
                },
                addGroupAppointment: {
                    icon: `mdi mdi-account-multiple-plus ${this.canCreate ? '' : 'disabled'}`,
                    click: this.canCreate ? ev => this.addGroupAppointment(ev) : false
                }
            },
            buttonText: {
                listWeek: this.translate.instant('list_week'),
                listDay: this.translate.instant('list_day'),
                agendaTwoDay: this.translate.instant('two_days'),
                resourceTimelineWeek: this.translate.instant('timeline_week'),
                resourceTimelineDay: this.translate.instant('timeline_one_day'),
            },

            initialDate: this.moment().format('YYYY-MM-DD'),
            // eventLimitText: this.translate.instant('more'),
            // columnFormat: this.translate.instant('column_format'),

            // waiting list
            datesSet: (info) => {
                this.updateWaitingListCount(info.view);

                const start = this.moment(info.start), end = this.moment(info.end);
                if (this.moment().isBetween(start, end) && _.get(config, "start_at_current_time", false))
                    this.calendar.scrollToTime(this.moment().format(this.timeFormat))

            },
            viewDidMount: (info) => {
                this.updateWaitingListCount(info.view)
            },

            eventDidMount: info => this.eventRender(info),
            eventWillUnmount: info => this.eventDestroy(info),
            eventClassNames: info => this.eventClassNames(info),
            eventDrop: info => this.eventResizeOrMove(info),
            eventResize: info => this.eventResizeOrMove(info),

            eventSources: [
                {
                    id: EVENT_SOURCE_ID, events: (info, success, error) => this.getEvents(info, success, error)
                }
            ],

            editable: this.canUpdate,
            droppable: this.canUpdate,
            selectable: this.canCreate,

            select: info => this.selectInterval(info),
            eventClick: this.canUpdate ? info => this.eventClick(info) : false,
            eventMouseEnter: (info) => this.eventMouseEnter(info),
            eventMouseLeave: (info) => this.eventMouseLeave(info),

            // nav link related
            navLinkDayClick: (date) => this.changeView(date),
            navLinkWeekClick: (date) => this.changeView(date),

            // loading related
            loading: isLoading => {
                $('md-progress-circular', this.element)[isLoading ? 'show' : 'hide']();
            }
        }
    }

    // toolbar methods
    calendarNavigation(ev) {
        this.menuCtrl.open(ev, CALENDAR_DATE_NAVIGATION_ELEMENT).then(ref => this.panelRef = ref);
    }

    navigationChange() {
        this.panelRef && this.panelRef.hide();
        this.calendar.gotoDate(this.navigationDate);
    }

    addAppointmentToWL(event) {
        this.callback({obj: null, event: {is_waiting_list: true}, jsEv: event});
    }

    addGroupAppointment(event) {
        this.groupCallback({obj: null, jsEv: event})
    }

    // nav link related
    changeView(date) {
        this.calendar.gotoDate(date);
        this.calendar.changeView('agendaDay');
    }

    // resource related
    resourceRender(info) {
        const {color, full_name} = info.resource.extendedProps;

        $(info.el).attr('data-date', this.moment(this.calendar.getDate()).format('YYYY-MM-DD'));

        if (color) {
            $(info.el).css('color', color);
        }

        if (full_name) {
            $(info.el).attr('title', full_name);
        }

        return info.el;
    }

    getResources(callback, error, useAbbr) {
        let currentResource = this.frontDeskService.resourceSubject.getValue();

        let promise = Promise.resolve([]);

        if (currentResource === 'agenda_resource') promise = this.frontDeskService.getAgendasResource(this.filter);
        if (currentResource === 'physician_resource') promise = this.physicianService.agendaResources(this.filter, useAbbr);

        promise.then(resources => {
            callback(resources);
            this.addDayEventCount();
        }, error);
    }

    reloadResource() {
        if (this.calendar) this.calendar.refetchResources();
    }

    // event related
    eventRender(info) {
        let $el = $(info.el);
        let event = this.getEventObject(info);

        // general classes
        $el.addClass('mn-event');
        if (event.id === "cd") return this.renderBusinessDay(event, $el, info.view.type);
        else if (event.is_pause) return this.renderPause(event, $el, info.view.type);
        else if (_.isString(event.id) && event.id.startsWith("background")) return this.renderUnavailability(event, $el, info.view.type);
        else {
            return this.renderRdv(event, $el, info.view.type);
        }

        // if (event.id === "cd") return this.renderBusinessDay(event, $el, info.view.type);
        // else if (!event.is_pause && _.chain(event.id).toNumber().isNaN().value()) return this.renderUnavailability(event, $el, info.view.type);
        // else if (event.is_pause) return this.renderPause(event, $el, info.view.type);
        // else return this.renderRdv(event, $el, info.view.type);
    }

    eventDestroy(info) {
        const $el = $(`[type=${info.view.type}]`, info.el);
        const scoop = angular.element($el).scope();

        if (scoop) scoop.$destroy();
    }

    eventClassNames(info) {
        let classes = ['mn-event'];
        let event = this.getEventObject(info);

        const viewName = info.view.type;
        const smallSlot = event.endMoment.diff(event.startMoment, 'minutes') === this.slotDuration;

        if (info.event.id === "cd" && viewName !== "dayGridMonth") {
            classes = _.concat(classes, 'mn-business-day');
        } else if (event.is_pause) {
            classes = _.concat(
                classes,
                viewName === "dayGridMonth" ? ['mn-pause-month-event'] : ["mn-pause", smallSlot ? 'mn-small-pause' : '']
            );
        } else if (_.isString(event.id) && event.id.startsWith("background")) {
            classes = _.concat(
                classes,
                viewName !== "dayGridMonth" ? ["mn-unavailable", smallSlot ? "mn-small-unavailable" : ''] : [],
                viewName === "dayGridMonth" && !(event['is_all_day'] || event['allDay']) ? ["mn-unavailable-month-event"] : []
            );
        } else {
            classes = _.concat(
                classes, 'mn-rdv', (viewName === 'listWeek' || viewName === 'listDay') ? ['mn-list-item'] : []
            );
        }

        // if (info.event.id === "cd" && viewName !== "dayGridMonth") classes = _.concat(classes, 'mn-business-day');
        // else if (!event['is_pause'] && _.chain(event.id).toNumber().isNaN().value()) {
        //     classes = _.concat(
        //         classes,
        //         viewName !== "dayGridMonth" ? ["mn-unavailable", smallSlot ? "mn-small-unavailable" : ''] : [],
        //         viewName === "dayGridMonth" && !(event['is_all_day'] || event['allDay']) ? ["mn-unavailable-month-event"] : []
        //     );
        // } else if (event.is_pause) classes = _.concat(
        //     classes,
        //     viewName === "dayGridMonth" ? ['mn-pause-month-event'] : ["mn-pause", smallSlot ? 'mn-small-pause' : '']
        // );
        // else classes = _.concat(
        //         classes, 'mn-rdv', (viewName === 'listWeek' || viewName === 'listDay') ? ['mn-list-item'] : []
        //     );

        return classes;
    }

    getEvents(info, success, error) {
        const query = {
            filter: this.filter,
            start: this.moment(info.start).format('YYYY-MM-DD'),
            end: this.moment(info.end).add(-1, 'days').format('YYYY-MM-DD'),
        };

        this.calendarService
            .getEvents(query, this.calendar, this.closingDays)
            .then(data => {
                success(data.map(item => this.toggleEventIdValue(item)));
                this.addDayEventCount();
            }, err => error(err));
    }

    toggleEventIdValue(item) {
        return this.calendarService.toggleEventIdValue(item);
    }

    eventDrop(info) {
        $(info.draggedEl).remove();
    }

    partialUpdateAppointment(appointment) {
        switch (appointment.event_type) {
            case "rdv":
                return this.frontDeskService.partialUpdateAppointment(appointment.id, appointment);
            case 'group-rdv':
                return this.groupAppointmentService.partialUpdateAppointment({id: appointment.id}, appointment);
            default:
                return this.q.when(null);
        }
    }

    eventResizeOrMove(info, drop = false) {
        const appointment = this.transformToAppointment(info.event);

        this.partialUpdateAppointment(appointment)
            .then(data => {
                if (drop) info.event.remove();
                else info.oldEvent.remove();

                this.successNotify(data);
            }, info.revert);
    }

    addDayEventCount() {
        const currentView = _.get(this.calendar, 'view.type');

        if (currentView !== 'dayGridMonth') {
            let headers = $(this.getHeaderElements(currentView), this.element);

            headers.each((i, header) => {
                let $header = $(header);

                const currentStart = _.get(this.calendar, 'view.currentStart');
                const headerDate = currentView === 'resourceTimelineDay' ? this.moment(currentStart).format('YYYY-MM-DD') : $header.attr('data-date');
                const resourceId = $header.attr('data-resource-id');

                const eventClass = `.mn-rdv${resourceId ? '.' + resourceId : ''}[event-date="${headerDate}"]`;
                const eventSize = $(eventClass, this.element).length;

                if ($header.find('.day-event-count').length === 0) this.handleCount($header, currentView);

                $('.day-event-count', $header).text(eventSize);
            });
        }
    }

    handleCount($header, currentView) {
        if (_.includes(['listWeek', 'listDay'], currentView)) $('div', $header).append('<span class="day-event-count" />');
        else if (_.includes(['resourceTimelineDay'], currentView)) $('.fc-datagrid-cell-cushion', $header).append('<span class="day-event-count" />');
        else if (_.includes(['agendaTwoDay', 'agendaDay', 'timeGridWeek'], currentView)) $('.fc-scrollgrid-sync-inner', $header).append('<span class="day-event-count" />');
        else $header.append('<span class="day-event-count" />');
    }

    getHeaderElements(currentView) {
        if (currentView === 'timeGridWeek') return '.fc-day[data-date]';
        else if (_.includes(['listWeek', 'listDay'], currentView)) return '.fc-list-day[data-date]';
        else if (_.includes(['agendaTwoDay', 'agendaDay'], currentView)) return '.fc-resource[data-date]';
        else if (_.includes(['resourceTimelineDay'], currentView)) return '.fc-resource[data-resource-id]';
    }

    selectInterval(info) {
        if (info.view.type === "dayGridMonth") this.changeView(info.start);
        else {
            const end = this.moment(info.end);
            const start = this.moment(info.start);
            const isDrag = this.moment.duration(end.diff(start)).asMinutes() !== this.slotDuration;

            const event = _.assign(
                this.handleResource(info.resource), {start, end, isDrag}
            );

            this.callback({obj: event, event: null, jsEv: info.jsEvent});
        }
    }

    eventClick(info) {
        const event_type = _.get(info, "event.extendedProps.event_type");
        const real_id = _.get(info, "event.extendedProps.real_id");


        switch (event_type) {
            case "rdv":
                this.callback({
                    obj: null,
                    event: this.toggleEventIdValue(_.assign({real_id}, info.event)),
                    jsEv: info.jsEvent
                });
                break;
            case "group-rdv":
                this.groupCallback({
                    obj: null,
                    objId: real_id,
                    jsEv: info.jsEvent
                });
                break;
        }
    }

    eventMouseEnter(info) {
        if (info.view.type === 'dayGridMonth' || info.view.type === 'resourceTimelineDay' || info.view.type === 'resourceTimelineWeek') return;

        let $el = $(info.el);
        let $parent = $el.parent();
        let $target = $(info.jsEvent.target);

        if ($target.is('.md-menu') || $target.is('.fc-event-resizer') || $el.is('.fc-list-event') || !$el.is('.mn-rdv')) return;

        this.timeoutKill = this.timeout(() => {
            $el.addClass("hovered");

            let t = $parent.css("top");
            let b = $parent.css("bottom");
            let r = $parent.css("right");

            let eventHeight = $('.layout-row', $el).height();
            let nb = -1 * (parseInt(t) + eventHeight);

            $el.data("bottom", parseInt(b));
            $el.data("right", r);

            let animation = {};

            if ((parseInt(b) > nb)) _.set(animation, 'bottom', nb);

            requestAnimationFrame(() => {
                $parent.addClass("hovered-event-container");
                $parent.css(animation);
                this.timeoutKill = null;
            });
        }, 500);
    }

    eventMouseLeave(info) {
        if (info.view.type === 'dayGridMonth') return;

        if (this.timeoutKill) {
            this.timeout.cancel(this.timeoutKill);
        }

        let $el = $(info.el);
        let $parent = $el.parent();

        if ($el.hasClass("hovered")) {
            let animation = {bottom: $el.data("bottom"), right: $el.data("right")};

            requestAnimationFrame(() => {
                $parent.css(animation);
                $parent.removeClass("hovered-event-container");
                $el.removeClass("hovered");
            });
        }
    }

    // resource related
    handleResource(resource) {
        if (resource) return _.chain(resource).get('id').split('_').thru(
            data => new Object({[data[0]]: {id: parseInt(data[1])}})
        ).value();
        else return {}
    }

    handleResources(event) {
        return _.chain(event.getResources()).reduce((data, item) => {
            let resource = _.chain(item).get('id').split('_').thru(i => new Object({[i[0]]: parseInt(i[1])})).value();
            return _.assign(data, resource);
        }, {}).value();
    }

    // waiting list related
    toggleWaitingList() {
        this.$waitingNav.toggleClass('md-locked-open');
        this.$waitingNav.toggleClass('md-closed');

        setTimeout(() => this.calendar.updateSize(), 430);
    }

    handleWaitingList(items) {
        this.waitingListItems = items.map(item => this.handleWaitingListItem(item));
        setTimeout(() => this.updateWaitingListCount());
    }

    handleWaitingListItem(item) {
        item.track_id = `${item.event_type}-${item.id}`;

        return item;
    }

    updateWaitingListCount(view) {
        if (this.calendar) this.element
            .parents('.agenda-dialog, .mn-module')
            .find('.md-toolbar-tools > h2')
            .text(this.calendar.view.title);

        const toggleButton = 'button.fc-waitingListToggle-button';
        const toggleCount = `${toggleButton} > .waiting-list-count`;

        if ($(toggleCount, this.element).length === 0) {
            $(toggleButton, this.element).append('<span class="waiting-list-count">0</span>');
        }

        $(toggleCount, this.element).text(this.waitingListItems.length);

        // update navigation date
        if (view) this.navigationDate = view.currentStart;
    }

    // helpers
    transformToAppointment(event) {
        // make more validation on date
        const date = this.moment(event.start).format(this.dateFormat);
        const start_time = this.moment(event.start).format(this.timeFormat);
        const end_time = event['is_waiting_list'] ? this.moment(event.start).add(event['reason_duration'], 'minutes').format(this.timeFormat) : this.moment(event.end).format(this.timeFormat);
        return _.assign({
            id: event.extendedProps.real_id ? event.extendedProps.real_id : event.id,
            event_type: event.extendedProps.event_type,
            is_waiting_list: false,
            date, start_time, end_time,
        }, this.handleResources(event));
    }

    successNotify(data) {
        return this.mnWebSocket.pub("frontdesk.Calendar.notify", {
                cmd: UPDATE,
                type: APPOINTMENT,
                event: data.id,
                event_type: data.event_type
            },
            false
        );
    }

    // event render related
    compileComponent(element, event, view) {
        let childScope = _.merge(this.scope.$new(), {event});

        let compiledDirective = this.compile(`<${element} type="${view}" event="event" />`);
        return compiledDirective(childScope);
    }

    renderBusinessDay(event, $el, viewName) {
        const dateToFind = `data-date='${event.startMoment.format('YYYY-MM-DD')}'`;

        if (viewName !== "dayGridMonth") {
            $el.on('mousedown click', ev => ev.stopPropagation());

            const $bg = $(`td.fc-daygrid-day[${dateToFind}]`, this.element);
            $bg.addClass("mn-bd-day");

            return $el.get(0);
        } else {
            const $container = $(`td.fc-daygrid-day[${dateToFind}]`, this.element);
            $container.addClass("mn-bd-day");

            $el.css('display', 'none');
            return null;
        }
    }

    renderUnavailability(event, $el, view) {
        if (!event.is_permanent) {
            let startDate = this.moment(event['start_date'], this.dateFormat).add(-1, 'd').set({hour: 23, minute: 59});
            let endDate = this.moment(event['end_date'], this.dateFormat).add(1, 'd').set({hour: 0, minute: 0});

            if (!event.startMoment.isBetween(startDate, endDate)) {
                $el.css('display', 'none');
                return null;
            }
        }

        const dateToFind = `data-date=${event.startMoment.format('YYYY-MM-DD')}`;

        if (event['is_all_day'] || event['allDay']) {
            if (view === 'dayGridMonth') {
                let $bg = $(`.fc-bg td[${dateToFind}]`, this.element);
                let $content = $(`td.fc-daygrid-day[${dateToFind}]`, this.element);

                $bg
                    .attr("data-id", event.id)
                    .addClass('mn-unavailable-day')
                    .css({'background-color': event.backgroundColor});

                $content
                    .addClass("mn-unavailable-day")

                $el.css('display', 'none');
                return null;
            } else {
                const $bg = $(`.fc-bg td[${dateToFind}]`, this.element);
                $bg.css({background: 'transparent'});
            }
        }

        $el.on('mousedown click', ev => ev.stopPropagation());
        let component = this.compileComponent('unavailable-calendar-element', event, view);
        $el.html(component);

        return $el.get(0);
    }

    renderPause(event, $el, view) {
        $el.on('mousedown', e => e.stopPropagation())
            .on('click', e => {
                e.stopPropagation();
                this.callback({obj: null, event, jsEv: e});
            });

        let component = this.compileComponent('pause-calendar-element', event, view);
        $el.html(component);

        return $el.get(0);
    }

    renderRdv(event, $el, view) {
        $el.attr('event-date', event.startMoment.format('YYYY-MM-DD'));

        let component = this.compileComponent(
            `${event.event_type}-calendar-element`,
            this.toggleEventIdValue(_.assign({}, event)),
            view
        );
        $el.html(component);

        return $el.get(0);
    }

    // event generations from info Event Render Hooks
    getEventObject(info) {
        return _.chain(info.event.extendedProps).cloneDeep().assign({
            id: info.event.id,
            title: info.event.title,
            allDay: info.event.allDay,
            endMoment: this.moment(info.event.end),
            startMoment: this.moment(info.event.start),
            backgroundColor: info.event.backgroundColor,
            date: this.moment(info.event.start).format(this.dateFormat),
        }).value();
    }
}

export default {
    controllerAs: "vm",
    controller: CalendarCtrl,
    bindings: {
        filter: "<",
        callback: "&",
        groupCallback: "&"
    },
    template: `
        <md-sidenav md-component-id="waiting-list-nav" class="mn-waiting-list-nav">
            <md-content dragula="'waiting-list-bag'">
                <div class="empty-content layout-row layout-align-start-center" ng-if="vm.waitingListItems.length === 0">
                    <md-icon md-font-icon="mdi-alert-circle-outline" md-font-set="mdi" aria-label="warning"></md-icon>
                    <span translate-once="no_element_to_show"></span>
                </div>
                <div ng-repeat="event in vm.waitingListItems track by event.track_id" class="waiting-list-event">
                    <rdv-calendar-element type="waiting-list" event="event" ng-if="event.event_type == 'rdv'"
                            ng-click="!vm.canUpdate || vm.eventClick({event: event, jsEvent: $event})" ng-disabled="!vm.canUpdate">
                    </rdv-calendar-element>
                    <group-rdv-calendar-element type="waiting-list" event="event" ng-if="event.event_type == 'group-rdv'"
                            ng-click="!vm.canUpdate || vm.eventClick({event: event, jsEvent: $event})" ng-disabled="!vm.canUpdate">
                    </group-rdv-calendar-element>
                </div>
            </md-content>
        </md-sidenav>
        <div class="calendar flex"></div>
        
        <md-progress-circular md-mode="indeterminate" md-diameter="40"></md-progress-circular>
    `, // or template
};
