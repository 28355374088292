import adaptivePlugin from '@fullcalendar/adaptive';
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import timeLinePlugin from "@fullcalendar/timeline";
//import resourceCommonPlugin from '@fullcalendar/resource-common';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import momentPlugin from '@fullcalendar/moment';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import frLocale from '@fullcalendar/core/locales/fr';

export const PRESETS = {
    locales: [frLocale],
    plugins: [
        adaptivePlugin,
        interactionPlugin,
        dayGridPlugin,
        listPlugin,
        timeGridPlugin,
        timeLinePlugin,
        // resourceCommonPlugin,
        resourceTimeGridPlugin,
        resourceTimelinePlugin,
        momentPlugin,
        momentTimezonePlugin
    ],

    height: 'parent',
    allDaySlot: false,
    nowIndicator: true,
    lazyFetching: false,
    slotEventOverlap: false,
    dayMaxEventRows: true,
    datesAboveResources: true,
    progressiveEventRendering: false,

    // drag & drop related
    dropAccept: ".mn-waiting-event",

    // nav link related
    navLinks: true,

    headerToolbar: {
        center: '',
        left: 'waitingListToggle,addWaitingList,addGroupAppointment prev,calendarButton,next today',
        right: 'dayGridMonth,timeGridWeek,listWeek,listDay,resourceTimelineDay,agendaTwoDay,agendaDay'
    },

    listDaySideFormat: false,
    listDayFormat: "dddd DD MMMM YYYY",

    // time line related
    resourceAreaWidth: "20%",

    // touch related
    longPressDelay: 400,

    views: {
        agendaTwoDay: {
            duration: {"days": 2},
            type: "resourceTimeGridDay",
            dayHeaderFormat: "dddd DD MMMM YYYY"
        },
        listDay: {
            type: "list",
            duration: {"days": 1},
            titleFormat: "dddd DD MMMM YYYY"
        },
        agendaDay: {
            type: 'resourceTimeGridDay',
            titleFormat: "dddd DD MMMM YYYY"
        }
    },
    buttonIcons: {
        prev: "mdi mdi-chevron-left",
        next: "mdi mdi-chevron-right",
        prevYear: "mdi mdi-chevron-double-left",
        nextYear: "mdi mdi-chevron-double-right"
    },

    // see about it
    schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source'
};
