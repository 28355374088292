/**
 * Created by amine on 13/10/2022.
 */
(function () {
    'use strict';

    class EditorContextualTemplateFormCtrl {
        constructor($mdDialog, editorContextualModelService, $scope, authService) {
            this.dialog = $mdDialog;
            this.editorContextualModelService = editorContextualModelService;
            this.scope = $scope;

            this.isRoot = authService.isRootProfile();

            this.editorOpts = {
                "auto_compile": false,
                "no_config_key": true,
                "has_misc_menu": true,
                "has_patient_menu": true,
                "has_physician_menu": true,
                "has_measurement_menu": true,
                "has_fire_pacs_exams_sharing": false,
            }

            this.contexts = [
                {
                    key: "observation",
                    label: "observation_title"
                },
                {
                    key: "exam",
                    label: "exam_record"
                },
                {
                    key: "prescription",
                    label: "prescription"
                },
                {
                    key: "letters",
                    label: "medical_certificates"
                }
            ]
        }

        static get $inject() {
            return ["$mdDialog", "editorContextualModelService", "$scope", "authService"];
        }

        $onInit() {
            this.model = !!this.model ? this.model : {title: "", content: "", is_bookmarked: false, context: {}};
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.editorContextualModelService.saveModel(this.model)
                .then(data => this.dialog.hide(data), _.noop);
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: EditorContextualTemplateFormCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("./editor-contextual-template-form.tpl.html"),
    };
})();