/**
 * Created by BETALOS on 14/07/2016.
 */
(function () {

    'use strict';

    require('./utils/globals');

    const angular = require('angular');

    const mnList = require('./components/list');
    const mnAutocompleteList = require('./components/autocomplete-list');
    const mnChipsList = require('./components/chips-list');
    const mnLocation = require('./components/location');

    const mnPhysician = require('./components/physician');
    const mnPhysicianContact = require('./components/physician-contact');

    const mnSearchModal = require('./components/search-modal');
    const mnInputSearchModal = require('./components/input-search-modal');
    const mnTable = require('./components/table');
    const mnToolbar = require('./components/toolbar');
    const mnSidenavContent = require('./components/side-nav');
    const mnSelect = require('./components/select');
    const mnSelectSearchHeader = require('./components/select-search-header');
    const mnAce = require('./components/ace');
    const mnSav = require('./components/sav');
    const mnBiologyTree = require('./components/biology-tree');
    const mnSystemParameter = require('./components/system-parameter');

    const mnSearchProcedure = require('./components/search-procedure');

    const mnModelSearch = require('./components/model-search');
    const mnExtApiSearch = require('./components/ext-api-search');
    const mnMultiSelect = require('./components/multi-select');


    const mnProcedureTable = require("./components/procedure-table");

    const mnNotification = require('./components/notification');

    const mnWebCam = require('./components/webcam');
    const mnVoiceRecognition = require('./components/voice-recognition');

    const mnDefaultTemplate = require('./components/default-print-template');
    const periodSetter = require('./components/period-setter');

    const multiConvention = require('./components/multi-convention');

    const tableSearchBar = require('./components/table-search-bar');
    const mnTableCustomFilter = require('./components/table-custom-filter');
    const mnTableFilterItem = require('./components/table-filter-item');
    const mnTableSideNav = require('./components/table-sidenav');
    const mnTableStyleRules = require('./components/table-style-rules');

    const mnRadioGroup = require("./directives/radio-group");
    const mnCheckBoxGroup = require("./directives/check-box-group");
    const mnAge = require('./directives/age');

    const mnAsyncValidation = require("./directives/async-validation");

    const mdSubmenu = require("./directives/sub-menu");

    const mnCapital = require("./directives/capital-field");

    const mnAnimateHeight = require("./directives/animate-height");

    const mnAutoFocus = require("./directives/autofocus");
    const mnSelectOnFocus = require("./directives/select-on-focus");
    const mnSrefActive = require("./directives/sref-active");
    const inputMdCell = require("./directives/input-md-cell");


    const mnDefaultValue = require('./directives/default-value');
    const mnActiveModule = require('./directives/active-module');

    const mnTableAnimate = require("./directives/table-animate");

    const mnTableSubmit = require("./directives/table-pagination-submit");
    const defaultChip = require("./directives/default-chip");

    const ModelSearchDirective = require("./directives/search-modal-directive");
    const mnRainbowIcon = require("./directives/mn-rainbow-icon");

    const listService = require('./services/listService');
    const locationService = require('./services/locationService');
    const physicianService = require('./services/physicianService');
    const contactService = require('./services/contactService');
    const tableService = require('./services/tableService');
    const medicineService = require('./services/medicineService');

    const notificationService = require('./services/notificationService');
    const conventionService = require('./services/conventionService');
    const customContactService = require('./services/customContactService');

    const mnAgeFilter = require('./filters/age-filter');
    const mnActiveModuleFilter = require('./filters/active-module-filter');

    const externalConnectionService = require('./services/externalConnectionService');


    const ContactsCtrl = require('./controllers/ContactsCtrl');


    angular
        .module("medinet")

        .controller('ContactsCtrl', ContactsCtrl)

        .component("mnList", mnList)
        .component("mnAutocompleteList", mnAutocompleteList)
        .component("mnChipsList", mnChipsList)
        .component("mnLocation", mnLocation)

        .component("mnPhysician", mnPhysician)
        .component("mnPhysicianContact", mnPhysicianContact)

        .component("mnSearchModal", mnSearchModal)
        .component("mnInputSearchModal", mnInputSearchModal)
        .component("mnTable", mnTable)
        .component("mnToolbar", mnToolbar)
        .component("mnSidenavContent", mnSidenavContent)
        .component("mnSelect", mnSelect)
        .component("mnSelectSearchHeader", mnSelectSearchHeader)

        .component("mnTableSearchBar", tableSearchBar)

        .component("mnAce", mnAce)
        .component("mnSav", mnSav)
        .component("mnBiologyTree", mnBiologyTree)
        .component("mnSystemParameter", mnSystemParameter)

        .component("mnModelSearch", mnModelSearch)
        .component("mnMultiSelect", mnMultiSelect)

        .component("mnSearchProcedure", mnSearchProcedure)

        .component("mnProcedureTable", mnProcedureTable)

        .component('mnNotification', mnNotification)

        .component('mnWebCam', mnWebCam)
        .component('mnVoiceRecognition', mnVoiceRecognition)

        .component('mnDefaultTemplate', mnDefaultTemplate)
        .component('multiConvention', multiConvention)

        .component('mnPeriodSetter', periodSetter)
        .component('mnTableCustomFilter', mnTableCustomFilter)
        .component('mnTableFilterItem', mnTableFilterItem)
        .component('mnTableSideNav', mnTableSideNav)
        .component('mnTableStyleRules', mnTableStyleRules)
        .component('mnExtApiSearch', mnExtApiSearch)


        .directive("mnRadioGroup", mnRadioGroup)
        .directive("mnCheckBoxGroup", mnCheckBoxGroup)
        .directive("mnAge", mnAge)
        .directive("mnDefaultValue", mnDefaultValue)
        .directive("mnActiveModule", mnActiveModule)
        .directive("mnAsyncValidation", mnAsyncValidation)
        .directive("mdSubmenu", mdSubmenu)
        .directive("mnCapital", mnCapital)
        .directive("mnAutoFocus", mnAutoFocus)
        .directive("mnSelectOnFocus", mnSelectOnFocus)
        .directive("mnSrefActive", mnSrefActive)
        .directive("mnAnimateHeight", mnAnimateHeight)
        .directive("mnTableAnimate", mnTableAnimate)
        .directive("mdCell", inputMdCell)
        .directive("mnTableSubmit", mnTableSubmit)
        .directive("defaultChip", defaultChip)

        .directive("modelSearchDirective", ModelSearchDirective)
        .directive("mnRainbowIcon", mnRainbowIcon)

        .service("listService", listService)
        .service("locationService", locationService)
        .service("physicianService", physicianService)
        .service("contactService", contactService)
        .service('tableService', tableService)

        .service('medicineService', medicineService)

        .service('notificationService', notificationService)
        .service('conventionService', conventionService)

        .service('externalConnectionService', externalConnectionService)
        .service('customContactService', customContactService)

        .filter("mnAge", mnAgeFilter)
        .filter("mnActiveModule", mnActiveModuleFilter)

})();
